import Header from '../../includes/dashboards/Header';
import GroupMenu from '../nav/GroupMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState } from "react";
import { postRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthHeader} from 'react-auth-kit'

const AddGroup = () => {
  const authHeader = useAuthHeader()

  useFetchAuth();

  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async(e) => {
    e.preventDefault()

    if (!name) {
      toast.error('Please add a Name', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }


    const data = { name }

    const addGroup = await postRequest(`groups/`, data, authHeader())

    if(addGroup.status === 200){

      setName('')
      toast.success(addGroup.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);

    }else{

      toast.success(addGroup.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }

  return (
    <div>  
      <ToastContainer />
        <body className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <GroupMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Add Group</h4>
                              <form className="forms-sample" onSubmit={onSubmit}>
                              
                              <div className="form-group">
                                  <label for="inputName1">Name</label>
                                  <input type="text" className="form-control" id="inputName1" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Add Group</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </body>
    </div>
  )
}

export default AddGroup;
