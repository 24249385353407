import React, { useState, useRef, useEffect } from "react";
import Styles from './includes/dashboards/Styles';
import { Link } from 'react-router-dom';
import logo from './../images/logo-black.png';
import { isEmail } from "validator";
import AuthService from "../services/auth.service";

function scrollToTop() {
  window.scrollTo(0, 0);
}



const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vfirstname = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The first name must be between 3 and 20 characters
      </div>
    );
  }
};

const vlastname = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The last name must be between 3 and 20 characters
      </div>
    );
  }
};

const vmobilenumber = (value) => {
  if (value.length < 8 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The mobile number be between 8 and 20 characters
      </div>
    );
  }
};

// const vemail = (value) => {
//   if (!isEmail(value)) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid email.
//       </div>
//     );
//   }
// };

const vaccounttype = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The account type be between 3 and 20 characters
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const vconfirmpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Signup = () => {

  const form = useRef();

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const [accounttype, setAccountType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const onChangeFirstName = (e) => {
    const firstname = e.target.value;
    setFirstName(firstname);
  };

  const onChangeLastName = (e) => {
    const lastname = e.target.value;
    setLastName(lastname);
  };

  const onChangeCompany = (e) => {
    const company = e.target.value;
    setCompany(company);
  };

  const onChangeMobileNumber = (e) => {
    const mobilenumber = e.target.value;
    setMobileNumber(mobilenumber);
  };

  const onChangeAccountType = (e) => {
    const accounttype = e.target.value;
    setAccountType(accounttype);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmpassword = e.target.value;
    setConfirmPassword(confirmpassword);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);
    //setLoading(true);
    
    if (1) {
      AuthService.register(firstname, lastname, company, mobilenumber, email, password, accounttype).then(
        (response) => {
          
          // setMessage(response.data.message);
          setMessage("User added, please login!");
          setSuccessful(true);
          //setLoading(false);
          
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
          //setLoading(false);
        }
      );
    }
  };

  
  return (
    <> 
      <Styles /> 

      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={ logo } alt="logo"  style={{ width: '50%' }}/>
                  </div>
                  <h4>New here?</h4>
                  <h6 className="fw-light">Signing up is easy. It only takes a few steps</h6>
                  <h3 className="fw-light">
                  <p>Thank you for your interest in our system. At <strong>Premium<span className="color-text" style={{ fontSize: '15px' }}>SMS</span></strong>, we take our users' privacy and security seriously, 
                  and we carefully control who we give access to. We do not allow spam accounts, as they can compromise the 
                  integrity of our platform.</p>

                  <p>If you would like to learn more about our services, please reach out to us at <a href="mailto:sales@premiumsms.co.za">sales@premiumsms.co.za</a> for an audit. 
                  We appreciate your interest and look forward to hearing from you.</p>
                  </h3>
                  <form className="pt-3" onSubmit={handleRegister} >

                  {/* {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful ? "alert alert-success" : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}

                  {!successful && (

                    <div>
                      <div className="form-group">
                        <input type="text" className="form-control form-control-lg" id="inputFistname1" placeholder="Fistname" 
                        name="firstname" value={firstname} onChange={onChangeFirstName} validations={[required, vfirstname]} />
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control form-control-lg" id="inputLastname1" placeholder="Lastname" 
                        name="lastname" value={lastname} onChange={onChangeLastName} validations={[required, vlastname]} />
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control form-control-lg" id="inputCompany1" placeholder="Company" 
                        name="company" value={company} onChange={onChangeCompany} />
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control form-control-lg" id="inputMobileNumber1" placeholder="Mobile Number"
                        name="mobilenumber" value={mobilenumber} onChange={onChangeMobileNumber} validations={[required, vmobilenumber]} />
                      </div>
                      <div className="form-group">
                        <input type="email" className="form-control form-control-lg" id="inputEmail1" placeholder="Email" 
                        name="email" value={email} onChange={onChangeEmail} />
                      </div>
                      <div className="form-group">
                        <select className="form-control form-control-lg pl-4" id="formControlSelect2"
                        name="accounttype" value={accounttype} onChange={onChangeAccountType} validations={[required, vaccounttype]}>
                          <option>Business</option>
                          <option>Personal</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input type="password" className="form-control form-control-lg" id="inputPassword1" placeholder="Password" 
                        name="password" value={password} onChange={onChangePassword} validations={[required, vpassword]}/>
                      </div>
                      <div className="form-group">
                        <input type="password" className="form-control form-control-lg" id="inputPassword2" placeholder="Confirm Password" 
                        name="confirmpassword" value={confirmpassword} onChange={onChangeConfirmPassword} validations={[required, vconfirmpassword]}/>
                      </div>
                      <div className="mb-4">
                        <div className="form-check">
                          <label className="form-check-label text-muted">
                            <input type="checkbox" className="form-check-input" />
                            I agree to all <Link to="/termsandconditions">Terms & Conditions</Link>
                          </label>
                        </div>
                      </div>
                      {/* <div className="mt-3">
                        <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit">SIGN UP</button>
                      </div>

                      <div className="form-group">
                      <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span className="ml-3">Register</span>
                      </button>
                    </div>

                     </div> 

                    )}*/} 

                    <div className="text-center mt-4 fw-light">
                        Already have an account? <Link to="/sign-in" className="text-primary" onClick={scrollToTop}>Login</Link>
                    </div> 

                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content-wrapper ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
      {/* <!-- container-scroller --> */}
    </>
  )
}

export default Signup
