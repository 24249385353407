import Header from "../../includes/dashboards/Header";
import Pagination from "react-js-pagination";
import GroupMenu from "../nav/GroupMenu";
import Footer from "../../includes/dashboards/Footer";
import Theme from "../../includes/dashboards/Theme";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../../requests/requests";
import { useFetchAuth } from "../../../hooks/auth";
import Papa from "papaparse";
import {useAuthHeader} from 'react-auth-kit'

let contactsInGroup = JSON.parse(localStorage.getItem("contactsInGroup"));
let contactsOffGroup = JSON.parse(localStorage.getItem("contactsOffGroup"));

const EditGroup = () => {
  const authHeader = useAuthHeader()
  // { groupsFromServer, contacts, contact_group }
  const { id } = useParams(); // getting url id

  useFetchAuth();

  const [name, setName] = useState("");
  const [contacts, setContacts] = useState([]);
  const [contact_group, setContactGroup] = useState([]);

  //
  const [group_id, setGroupID] = useState("");
  const [fileData, setFileData] = useState({});
  const [activePage, setActivePage] = useState(1);


  useEffect(() => {
    async function fetchData() {
      refreshGroups();
      const group = await getRequest(`groups/${id}`, authHeader());
      
      // Assuming group is an array with a single item
      if (group.length > 0) {
        const selectedGroup = group[0];
        setName(selectedGroup.name);
        setGroupID(selectedGroup.id);
      }
    }
    fetchData();
  }, []);


  async function refreshGroups(){
    const [contacts, contact_group] = await Promise.all([
      getRequest(`contacts/`, authHeader()),
      getRequest(`groups/contactGroup/`, authHeader()),
    ]);
    setContacts(contacts);
    setContactGroup(contact_group);
  }
  

  const handleCSVFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const result = Papa.parse(event.target.result, {
        header: true, // assume the first row is the header row
      });
      setFileData(result.data);
    };
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please add a Name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    const data = { name };

    const updateGroup = await patchRequest(`groups/${id}`, data, authHeader());

    if (updateGroup.status === 200) {
      toast.success(updateGroup.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
    } else {
      toast.error(updateGroup.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
    }
  };

  // Add to Group
  const addToGroup = async (contact_id) => {
    const group_id = id;
    const data = { contact_id, group_id };
    const res = await postRequest(`groups/addContact`, data, authHeader());
    refreshGroups();
    return res;
  };

  // Remove from Group
  const removeFromGroup = async (contact_group_id) => {
    const res = await deleteRequest(`groups/removeContact/${contact_group_id}`, authHeader());
    refreshGroups();
    return res;
  };

  // Contacts In Group
  const inGroup = async () => {
    localStorage.setItem("contactsInGroup", JSON.stringify(true));
    localStorage.setItem("contactsOffGroup", JSON.stringify(false));

    window.location.reload();
  };

  // Contacts Off Group
  const offGroup = async () => {
    localStorage.setItem("contactsInGroup", JSON.stringify(false));
    localStorage.setItem("contactsOffGroup", JSON.stringify(true));

    window.location.reload();
  };

  const handleBulkUpload = async (e) => {
    e.preventDefault();

    if (fileData.length) {
      await Promise.all(
        fileData.map(async (file) => {
          if (file.Name !== "") {
            const data = {
              first_name: file.Name,
              last_name: file.Surname,
              mobile_number: file.Mobile,
              email: file.Email,
              group_id: [],
            };

            try {
              let getContactMobile = await getRequest(
                `contacts/mobile/${file.Mobile}`, authHeader()
              );

              if (getContactMobile.length < 1) {
                await postRequest(`contacts/`, data, authHeader());
                console.log(`Added contact ${file.Name}`);

                getContactMobile = await getRequest(
                  `contacts/mobile/${file.Mobile}`, authHeader()
                );
              }

              const contact_id = getContactMobile[0].id;

              const result = await addToGroup(contact_id);

              if (result.status === 200) {
                toast.success(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000
                });
              } else {
                toast.success(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000
                });
              }
            } catch (error) {
              console.error(`Error adding contact ${file.Name}:`, error);
            }
          }
        })
      );
    } else {
      toast.success("Please select a csv file with data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }
  };

  const itemsPerPage = 10;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const inGroupContacts = [];

  contacts.map((contact, index) => {
    let available = false;
    let group_contact_id;
    contact_group.forEach((contactGroup) => {
      if (
        contactGroup.contact_id === contact.id &&
        contactGroup.group_id === group_id
      ) {
        group_contact_id = contactGroup.contact_group_id;
        available = true;
        inGroupContacts.push(contact);
      } 
        
    });

  });

  const offGroupContacts = contacts.filter(contact => !inGroupContacts.includes(contact));

  const totalItems = contactsInGroup
    ? inGroupContacts.length
    : contactsOffGroup
    ? offGroupContacts.length
    : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  
  const currentContactsInGroup = inGroupContacts.slice(startIndex, endIndex);
  const currentContactsOffGroup = offGroupContacts.slice(startIndex, endIndex);

  return (
    <div>
      <ToastContainer />
      <body className="with-welcome-text">
        <div className="container-scroller">
          <Header />
          {/* <!-- partial --> */}
          <div className="container-fluid page-body-wrapper">
            <Theme />

            <GroupMenu />

            {/* <!-- partial --> */}

            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Edit Group</h4>
                        <form className="forms-sample" onSubmit={onSubmit}>
                         
                          <div className="form-group">
                            <label for="inputName1">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              id="inputName1"
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            Update Group
                          </button>
                        </form>

                        <form className="forms-sample mt-5">

                          <div className="form-group">
                            <label htmlFor="inputCSV1">
                              Upload bulk from csv?
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="inputCSV1"
                              accept=".csv"
                              onChange={handleCSVFileChange}
                            />
                            {/* {fileData && <p>Selected file: {fileData.name}</p>} */}
                          </div>

                          <input
                            type="submit"
                            className="btn btn-primary me-2"
                            value="Add Bulk Contacts"
                            onClick={handleBulkUpload}
                          />
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body row">
                        <div className="col-lg-8">
                          <h4 className="card-title">Contacts</h4>
                        </div>
                        <div className="col-lg-4 float-right">
                          <button
                            onClick={() => inGroup()}
                            className="btn btn-primary m-1"
                            disabled={contactsInGroup}
                          >
                            Contacts In Group
                          </button>
                          <button
                            onClick={() => offGroup()}
                            className="btn btn-danger m-1"
                            disabled={contactsOffGroup}
                          >
                            Contacts Off Group
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <table id="order-listing" className="table">
                                <thead>
                                  <tr>
                                    <th> # </th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Organization</th>
                                    <th>Mobile Number</th>
                                    <th>Email</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {contactsInGroup
                                    ? currentContactsInGroup.map((contact, index) => {
                                        let available = false;
                                        let group_contact_id;
                                        contact_group.forEach(
                                          (contactGroup) => {
                                            if (
                                              contactGroup.contact_id ===
                                                contact.id &&
                                              contactGroup.group_id === group_id
                                            ) {
                                              group_contact_id =
                                                contactGroup.contact_group_id;
                                              available = true;
                                              return;
                                            }
                                          }
                                        );

                                        if (available) {
                                          return (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{contact.first_name}</td>
                                              <td>{contact.last_name}</td>
                                              <td>{contact.company}</td>
                                              <td>{contact.mobile_number}</td>
                                              <td>{contact.email}</td>
                                              <td>
                                                {available ? (
                                                  <button
                                                    onClick={() =>
                                                      removeFromGroup(
                                                        group_contact_id
                                                      )
                                                    }
                                                    className="btn btn-outline-danger m-1"
                                                  >
                                                    Remove From Group
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      addToGroup(contact.id)
                                                    }
                                                    className="btn btn-outline-primary m-1"
                                                  >
                                                    Add To Group
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      })
                                    : contactsOffGroup
                                    ? currentContactsOffGroup.map((contact, index) => {
                                        let available = false;
                                        let group_contact_id;
                                        contact_group.forEach(
                                          (contactGroup) => {
                                            if (
                                              contactGroup.contact_id ===
                                                contact.id &&
                                              contactGroup.group_id === group_id
                                            ) {
                                              group_contact_id =
                                                contactGroup.contact_group_id;
                                              available = true;
                                              return;
                                            }
                                          }
                                        );

                                        if (!available) {
                                          return (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{contact.first_name}</td>
                                              <td>{contact.last_name}</td>
                                              <td>{contact.company}</td>
                                              <td>{contact.mobile_number}</td>
                                              <td>{contact.email}</td>
                                              <td>
                                                {available ? (
                                                  <button
                                                    onClick={() =>
                                                      removeFromGroup(
                                                        group_contact_id
                                                      )
                                                    }
                                                    className="btn btn-outline-danger m-1"
                                                  >
                                                    Remove From Group
                                                  </button>
                                                ) : (
                                                  <button
                                                    onClick={() =>
                                                      addToGroup(contact.id)
                                                    }
                                                    className="btn btn-outline-primary m-1"
                                                  >
                                                    Add To Group
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      })
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="pagination-wrapper mt-4">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={totalItems}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>

            {/* <!-- main-panel ends --> */}
          </div>

          {/* <!-- page-body-wrapper ends --> */}
        </div>

        {/* <!-- container-scroller --> */}
      </body>
    </div>
  );
};

export default EditGroup;
