import React, { useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import './table.css'

export default function Table({
  data,
  columns,
  title,
  wrapper="content-wrapper",
  currentPage,
  onPageChange,
}) {

  const options = [5, 10, 20, 50, 100];
  const [itemsPerPage, setItemsPerPage] = useState(5); // default value is 10
  const [searchTerm, setSearchTerm] = useState("");
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  
  const currentItems = data
  
    .filter((item) =>
      Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .slice(startIndex, endIndex);

  const exportToCSV = () => {
    const csv = [      columns.join(","), // header row      ...data.map((item) => columns.map((col) => item[col]).join(",")),
    ].join("\n");

    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = `${title}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [columns],
      body: data.map((item) => columns.map((col) => item[col])),
    });

    doc.save(`${title}.pdf`);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    onPageChange(1); // go back to the first page when changing items per page
  };
  

  return (
    <div className={wrapper}>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div className="row">
                  <div className="col-lg-5 row">

                    <div className="col-8">
                        <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control mb-3"
                        />
                    </div>

                    <div className="col-4">
                        <div className="dropdown d-inline-block mr-2">
                            <button
                            className="btn border border-secondary"
                            type="button"
                            id="itemsPerPageDropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >
                            {itemsPerPage} <i className="fa fa-caret-down"  aria-hidden="true"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="itemsPerPageDropdown">
                            {options.map((option) => (
                                <button
                                key={option}
                                className="dropdown-item"
                                onClick={handleItemsPerPageChange}
                                value={option}
                                >
                                {option}
                                </button>
                            ))}
                            </div>
                        </div>
                    </div>

                    
                  </div>
                  <div className="col-lg-7 text-right">
                    <button className="btn btn-primary text-white mr-2" onClick={() => window.print()}>
                    <i className="fa fa-print" aria-hidden="true"></i> Print
                    </button>
                    <ReactHTMLTableToExcel
                      className="btn btn-success text-white mr-2"
                      table="order-listing"
                      filename={`${title}`}
                      sheet="Sheet"
                      buttonText={
                        <>
                          <i className="fa fa-file-excel mr-1" aria-hidden="true"></i> 
                            Export to Excel
                        </>
                      }
                    />
                    {/* <button className="btn btn-danger" onClick={exportToPDF}>
                      Export to PDF
                    </button>
                    <button className="btn btn-info ml-2" onClick={exportToCSV}>
                      Export to CSV
                    </button> */}
                  </div>
                </div>
                <table id="order-listing" className="table">
                  <thead>
                    <tr>
                      {columns.map((column) => (
                        <th key={column}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item) => (
                      <tr key={item.id}>
                        {columns.map((column) => (
                          <td key={column}>{item[column.toLowerCase()]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  
                </table>

                <nav className="mt-3">
                    <ul className="pagination">
                        <li className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
                            <a className="page-link" href="#" onClick={() => onPageChange(currentPage - 1)}> Previous</a>
                        </li>

                        {Array.from({length: Math.ceil(data.length / itemsPerPage),}).map((_, index) => {
                        if (index + 1 === 1 || index + 1 === Math.ceil(data.length / itemsPerPage)) {
                            // display the first or last page number
                            return (
                            <li className={`page-item${currentPage === index + 1 ? " active" : ""}`} key={index}>
                                <a className="page-link" href="#" onClick={() => onPageChange(index + 1)}>{index + 1}</a>
                            </li>
                            );
                        } else if (index + 1 === currentPage) {
                            // highlight the current page
                            return (
                            <li className="page-item active" key={index}>
                                <span className="page-link">{index + 1}
                                    <span className="sr-only">(current)</span>
                                </span>
                            </li>
                            );
                        } else if (
                            index + 1 >= currentPage - 2 && 
                            index + 1 <= currentPage + 2
                        ) {
                            // show only 5 page numbers around the current page
                            return (
                            <li className="page-item" key={index}>
                                <a className="page-link" href="#" onClick={() => onPageChange(index + 1)}>
                                    {index + 1}
                                </a>
                            </li>
                            );
                        } else if (
                            (index + 1 === currentPage - 3 && currentPage > 5) ||
                            (index + 1 === currentPage + 3 && currentPage < Math.ceil(data.length / itemsPerPage) - 4)
                        ) {
                            // display an ellipsis before or after the current page range
                            return (
                            <li className="page-item" key={index}>
                                <span className="page-link">...</span>
                            </li>
                            );
                        } else {
                            // don't show this page number
                            return null;
                        }
                        })}

                        <li className={`page-item${ currentPage === Math.ceil(data.length / itemsPerPage) ? " disabled" : "" }`}>
                            <a className="page-link" href="#" onClick={() => onPageChange(currentPage + 1)}>
                                Next
                            </a>
                        </li>
                    </ul>
                    </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
