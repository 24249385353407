import Header from '../../includes/dashboards/Header';
import UserMenu from '../nav/UserMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getRequest, postRequest } from '../../../requests/requests';
import { useFetchAuth, useCheckClient } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthHeader } from 'react-auth-kit'

const AddSMS = () => {
  const authHeader = useAuthHeader()

  const {id} = useParams(); // getting url id

  useFetchAuth();
  useCheckClient();
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [mobile_number, setMobileNumber] = useState('')
  const [packages, setPackages] = useState([])
  const [package_id, setPackageId] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    async function fetchData() {

      const packages = await getRequest(`packages/`, authHeader())
      setPackages(packages)

      const user = await getRequest(`users/${id}`, authHeader());
      user.map((user) => {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setMobileNumber(user.mobile_number);
      })

    }
    fetchData()
  })


  const onSubmit = async(e) => {
    e.preventDefault()

    const user_id = id
    const payment_method = "Cash"

    const pack = { user_id, package_id, payment_method }

    const subscribe = await postRequest(`user-package/`, pack, authHeader())
  
    if(subscribe.status === 200){

      toast.success(subscribe.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
    }else{

      toast.error(subscribe.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }


  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <UserMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Add User Package</h4>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                <div className="form-group">
                                  <label htmlFor="inputFistname1">Fistname *</label>
                                  <input type="text" className="form-control" id="inputFistname1" disabled value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputLastname1">Lastname *</label>
                                  <input type="text" className="form-control" id="inputLastname1" disabled value={last_name} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputMobileNumber1">Mobile Number *</label>
                                  <input type="text" className="form-control" id="inputMobileNumber1" disabled value={mobile_number} onChange={(e) => setMobileNumber(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputPackage">Package *</label>
                                  <select type="text" className="form-control" id="inputPackage" onChange={(e) => setPackageId(e.target.value)} >
                                  <option>Select Package</option>
                                  {packages.map((packag, index) => (
                                    <option key={index} value={packag.id} selected={packag.id === package_id}>
                                      {packag.name}
                                    </option>
                                  ))}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Add Package</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default AddSMS
