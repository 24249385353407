import Header from '../includes/dashboards/Header';
import DashMenu from './nav/DashMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';
import { useFetchDashboard } from '../../hooks/client/client';
import React, { useState } from "react";
import { useFetchAuth, useCheckAdmin } from "../../hooks/auth";
import Table from "./../../components/Table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postRequest } from '../../requests/requests';
import {useAuthHeader} from 'react-auth-kit'

const Dashboard = () => {

  const authHeader = useAuthHeader()

  useFetchAuth();
  useCheckAdmin();
  const { packages, contacts, templates, groups, user_packages, messages, lastmessage, lastMessageSuccessful, lastMessageFailed, sentMessages, failedMessages, scheduledMessages, no_of_sms, loading } = useFetchDashboard();

  //Just getting the last package user subcribed to
  let user_package_name; 
  user_packages.map((user_package) => (
    packages.map((packag) => (
      user_package.package_id === packag.id && ( user_package_name = packag.name )
    ))
  ))

  const userSchedules = [];

  scheduledMessages.forEach((schedule) => {
    failedMessages.forEach((message) => {
      if (schedule.message_id === message.id) {
        userSchedules.push(schedule);
      }
    });
  });

  const [currentPage, setCurrentPage] = useState(1);
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const messagesData = (messages, Resend) => {
    return messages.map((message, i) => {
      const contact = contacts.find(c => c.id === message.contact_id);
      return {
        id: i + 1,
        body: message.body,
        mobile: contact ? contact.mobile_number : "",
        date: message.created_date.split('T')[0],
        action: (
          <>
            {!message.status ? (
              <button onClick={() => Resend(message.id, message.contact_id, message.body, "SMS")} className="btn btn-outline-success border border-success m-1">
                <i className="fa fa-paper-plane" aria-hidden="true"></i> Resend
              </button>
            ) : (
              <button className="btn btn-outline-primary border border-primary m-1">
                 Sent
              </button>
            )}
          </>
        ),
      };
    });
  };
  

  // Resend SMS
  const Resend = async (message_id, contact_id, short_message, message_type) => {

    const message = { message_id, contact_id, short_message, message_type }

    const resendMessage = await postRequest(`messages/resend`, message, authHeader())
  
    if(resendMessage.status === 200){

      toast.success(resendMessage.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });

    }else{

      toast.error(resendMessage.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      
  }
}
  

  return loading ? (
    <div className="loading-container">
      <div className="m-3">Loading</div>
      <span className="spinner-border spinner-border-sm"></span>
    </div>

    ) : (
  
    <>  
    <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <DashMenu />

                {/* <!-- partial --> */}

                    <div className="main-panel">
                      <div className="content-wrapper">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="home-tab">
                              <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                                
                              </div>
                              <div className="tab-content tab-content-basic">
                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview"> 
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="statistics-details mb-0">

                                    <div className="row">
                                      <div className="col-sm-3 grid-margin">
                                        <div className="card">
                                          <div className="card-body">
                                            <div>
                                              <p className="card-title card-title-dash font-weight-medium">Sent SMS</p>
                                              <h6 className="rate-percentage d-flex justify-content-between">{ sentMessages.length } <span className="text-success text-medium d-flex align-items-center"><i className="mdi mdi-account-check me-2 icon-md"></i></span></h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                        <div className="col-sm-3 grid-margin">
                                          <div className="card">
                                            <div className="card-body">
                                              <div>
                                                <p className="card-title card-title-dash font-weight-medium">Failed SMS</p>
                                                <h6 className="rate-percentage d-flex justify-content-between align-items-center">{ failedMessages.length } <span className="text-danger text-medium d-flex align-items-center"><i className="mdi mdi-account-minus me-2 icon-md"></i></span></h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-3 grid-margin">
                                          <div className="card">
                                            <div className="card-body">
                                              <div>
                                                <p className="card-title card-title-dash font-weight-medium">Scheduled SMS</p>
                                                <h6 className="rate-percentage d-flex justify-content-between">{ userSchedules.length } <span className="text-success text-medium d-flex align-items-center"><i className="mdi mdi-calendar me-2 icon-md"></i></span></h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-3 grid-margin">
                                          <div className="card">
                                            <div className="card-body">
                                              <div>
                                                <p className="card-title card-title-dash font-weight-medium">SMS Templates</p>
                                                <h6 className="rate-percentage  d-flex justify-content-between">{ templates.length } <span className="text-danger text-medium d-flex align-items-center"><i className="mdi mdi-account-box me-2 icon-md "></i></span></h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-sm-4 grid-margin">
                                          <div className="card">
                                            <div className="card-body">
                                              <div>
                                                <p className="card-title card-title-dash font-weight-medium">Package: { user_package_name } </p>
                                                <h6 className="rate-percentage d-flex justify-content-between">SMS Left: { no_of_sms } <span className="text-success text-medium d-flex align-items-center"><i className="mdi mdi-cash me-2 icon-md"></i></span></h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                          <div className="col-sm-4 grid-margin">
                                            <div className="card">
                                              <div className="card-body">
                                                <div>
                                                  <p className="card-title card-title-dash font-weight-medium">Contacts</p>
                                                  <h6 className="rate-percentage d-flex justify-content-between align-items-center">{ contacts.length } <span className="text-danger text-medium d-flex align-items-center"><i className="mdi mdi-account-box me-2 icon-md"></i></span></h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 grid-margin">
                                            <div className="card">
                                              <div className="card-body">
                                                <div>
                                                  <p className="card-title card-title-dash font-weight-medium">Groups</p>
                                                  <h6 className="rate-percentage d-flex justify-content-between">{ groups.length } <span className="text-success text-medium d-flex align-items-center"><i className="mdi mdi-group me-2 icon-md"></i></span></h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>


                                          <div className='grid-margin'>

                                            <Table
                                              data={messagesData(lastmessage, Resend)}
                                              columns={["ID", "Body", "Mobile", "Date", "Action"]}
                                              title={"Your last message has "+ lastMessageSuccessful+" Successful, "+lastMessageFailed+" Failed"}
                                              wrapper=""
                                              currentPage={currentPage}
                                              onPageChange={handlePageChange}
                                            />

                                          </div>
                                          

                                        </div>


                                    </div>
                                  </div>
                                
                                </div> 
                                  {/* <div className="row">
                                    <div className="col-lg-12 d-flex flex-column">
                                      <div className="row flex-grow">
                                        <div className="col-12 col-lg-4 col-lg-12 grid-margin stretch-card">
                                          <div className="card card-rounded">
                                            <div className="card-body">
                                              <div className="d-sm-flex justify-content-between align-items-start">
                                                <div>
                                                <h4 className="card-title card-title-dash">Performance Line Chart</h4>
                                                <h5 className="card-subtitle card-subtitle-dash">Data taken from the last 30 days</h5>
                                                </div>
                                                <div id="performance-line-legend"></div>
                                              </div>
                                              <div className="chartjs-wrapper mt-4">
                                              <LineChart />
                                                <canvas id="performaneLine"></canvas> 
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- content-wrapper ends --> */}
                      
                  <Footer />

                </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </>
  )
}

export default Dashboard
