import Header from '../includes/dashboards/Header';
import SenderIDMenu from './nav/SenderIDMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { getRequest, patchRequest } from '../../requests/requests';
import { useFetchAuth } from "../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthHeader, useAuthUser} from 'react-auth-kit'

const SenderID = () => {
  const authHeader = useAuthHeader()

  const auth = useAuthUser()()

  useFetchAuth();

  const id = auth?.userID

  const [sender_ID, setSenderID] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    async function fetchData() {
      const user = await getRequest(`users/${auth?.userID}`, authHeader())
      user.map((user) => {
        setSenderID(user.sender_ID)
      })
      
    }
    fetchData()
  },[])

  const onSubmit = async(e) => {
    e.preventDefault()

    if (!sender_ID) {
      toast.error('Please add a sender ID', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (sender_ID.length > 8) {
      toast.error('Please we need 11 charactors', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    const data ={ requested_sender_ID:sender_ID }

    const request_sender_ID = await patchRequest(`users/request/${id}`, data, authHeader())

    if(request_sender_ID.status === 200){
      setSenderID("")
      toast.success('Sender ID requested, our team will look at it and approve!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
    }else{

      toast.error(request_sender_ID.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
  }

  return (
    <div>  
        <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <SenderIDMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Request Sender ID</h4>
                              <p className="card-description">Please provide a 11 alpha or numeric characters</p>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                <div className="form-group">
                                  <label htmlFor="inputContacts1">Sender ID</label>
                                  <input type="text" className="form-control form-control-lg" value={ sender_ID } onChange={(e) => setSenderID(e.target.value)} id="inputSenderID" />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Request Sender ID</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default SenderID
