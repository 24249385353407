import Header from '../../includes/dashboards/Header';
import TemplateMenu from '../nav/TemplateMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState } from "react";
import { postRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthHeader} from 'react-auth-kit'

const AddTemplate = () => {
  const authHeader = useAuthHeader()

  useFetchAuth();
  const [category, setCategory] = useState('')
  const [body, setBody] = useState('')
  const [loading, setLoading] = useState(false)
  const [charactersLeft, setCharactersLeft] = useState(149);
  
  const onSubmit = async(e) => {
    e.preventDefault()

    if (!body) {
      toast.error('Please add template message', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (body.length > 149) {
      toast.error('Template must not be more than 149 characters', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }


    const data = { category, body }

    const addTemplate = await postRequest(`templates/`, data, authHeader())

    if(addTemplate.status === 200){
      
      setCategory('')
      setBody('')

      toast.success(addTemplate.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
    
    }else{

      toast.error(addTemplate.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }

  const handleBodyChange = (e) => {
    const value = e.target.value;

    if (value.length <= 149) {
      setBody(value);
      setCharactersLeft(149 - value.length);
    } else {
      setBody(value.slice(0, 149));
    }
  }; 

  return (
    <div>  
        <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <TemplateMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Add Template</h4>
                              <p className="card-description">
                                Please make sure that you write a short message
                                (149 characters long)<br></br>
                                <strong>Dynamic fields, use [name] for name, [surname] for surname, [organization] for organization, [mobile] for  mobile, [email] for email</strong> <br></br>
                                <strong>CSV Dynamic fields, use [name] for name, [surname] for surname, [organization] for organization, [mobile] for  mobile, [email] for email, [activity] for activity, [time] for time, [amount] for amount</strong>
                              </p>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                <div className="form-group">
                                  <label htmlFor="inputCategory1">Category</label>
                                  <input type="text" className="form-control" id="inputCategory1" value={category} onChange={(e) => setCategory(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputMessage1">Template Message (149 Characters) *</label>
                                  <p className={`${charactersLeft > 30 ? 'text-green' : 'text-red'}`}>{charactersLeft} Characters Left</p>
                                  <textarea type="text" className="form-control" id="inputMessage1" value={body} onChange={handleBodyChange} cols="10" rows="15" maxLength={149}></textarea>
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Add Template</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default AddTemplate
