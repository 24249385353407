import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import home_font from '../assets/img/home-font.png';
import { getHomeRequest } from '../requests/requests';
function scrollToTop() {
    window.scrollTo(0, 0);
  }
  

const Home = () => {

    const [packages,setpackages] = useState([])

    useEffect(()=>{
        async function fetchData() {
        const packages = await getHomeRequest("home/packages/")
        setpackages(packages)
        }
        fetchData()
    },[packages])


  return (
    <>  
        <Header />
        <Menu />
        
        <div className="home-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-4">
                                <div className="banner-image">
                                    <img src={ home_font } alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8">
                                <div className="main-banner-content">
                                    <h1>Transform Your Marketing with our Bulk SMS</h1>
                                    <p>Unlock the power of SMS marketing! With our bulk SMS platform, 
                                        you can reach thousands of customers in seconds and increase your sales and engagement. 
                                        Create targeted campaigns, track your results, and grow your business with our easy-to-use platform. 
                                        See the difference today!</p>
                                    <p className="header-subtitle">All in one SMS platform.</p>
                                    <div className="banner-btn">
                                        <Link className="btn btn-primary" to="/sign-in" target={'_blank'}>
                                        Login/Get Started
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grey-cr-left"></div>
        </div>


        {/* Start Pricing Section  */}
            <section id="price" className="price-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Our Packages</h2>
                            </div>
                        </div>
                        
                        
                        {packages.map((our_package, index) => (

                            <div key={index} className="col-lg-4 col-md-6 price-item">
                                <div className="pricing-content">
                                    <div className="price-heading">
                                        <div className="price-name">
                                            <h3>{ our_package.name }</h3>
                                            <span className="price-small-text">R</span>
                                            <h2>{ our_package.price }</h2>
                                            <span className="price-small-text"></span>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <ul>
                                            <li><i className="fa fa-check"></i> { our_package.sms } SMSes</li>
                                            <li><i className="fa fa-check"></i> { our_package.support } Support</li>
                                            <li><i className="fa fa-check"></i> { our_package.sender_ID ? "Custom Sender ID" : "No Custom Sender ID" }</li>
                                            <li><i className="fa fa-check"></i> { our_package.requests } Send Requests</li>
                                            <li><i className="fa fa-check"></i> { our_package.sms_report ? "SMS Reports" : "No SMS Reports" }</li>
                                            <li><i className="fa fa-check"></i> { our_package.subscription_report ? "Subcription Report" : "No Subcription Report" }</li>


                                            {/* <li className="offer-list-none"><i className="fa fa-times"></i> <del>Lifetime Free Updates</del> </li> */}


                                        </ul>
                                    </div>
                                    <div className="price-btn"> <Link to="/buy-package" className="default-btn" onClick={scrollToTop}>Get Started <span></span></Link> </div>
                                </div>
                            </div>

                            ))}


                    </div>
                </div>
            </section>
            {/* End Pricing Section */}

        <Footer />
    </>
  )
}

export default Home
