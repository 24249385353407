import Header from '../../includes/dashboards/Header';
import ContactMenu from '../nav/ContactMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { getRequest, deleteRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import Table from "./../../../components/Table";
import {useAuthHeader} from 'react-auth-kit'

const Contacts = () => {
  const authHeader = useAuthHeader()

  useFetchAuth();

  const [contacts,setContacts] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  useEffect(()=>{
    async function fetchData() {
      const contacts = await getRequest("contacts/", authHeader())
      setContacts(contacts)
    }
    fetchData()
  },[contacts])


  const onDeleteContact = async (id) => {

    return await deleteRequest(`contacts/${id}`, authHeader())

  }

  const contactsData = (contacts, onDeleteContact) => {
    return contacts.map((contact, i) => ({
      id: i + 1,
      name: contact.first_name,
      surname: contact.last_name,
      mobile: contact.mobile_number,
      organization: contact.company,
      email: contact.email,
      actions: (
        <>
          <Link to={`/edit-contact/${contact.id}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-eye" aria-hidden="true"></i> View</Link>
          <button onClick={() => onDeleteContact(contact.id)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <ContactMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <Table
                      data={contactsData(contacts, onDeleteContact)}
                      columns={["ID", "Name", "Surname", "Mobile", "Organization", "Email", "Actions"]}
                      title="Contacts"
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Contacts
