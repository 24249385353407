import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'

export function useFetchAuth() {

  let navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser()()

  useEffect(() => {

    if(!isAuthenticated()){
      navigate("/sign-in");
    }else{
      
    }
    
  }, []);

}


export function useCheckAdmin() {

  let navigate = useNavigate();
  const auth = useAuthUser()()

  useEffect(() => {
    if(auth?.admin){
      navigate("/admin/dashboard");
    }
  }, []);

}

export function useCheckClient() {

  let navigate = useNavigate();
  const auth = useAuthUser()()

  useEffect(() => {
    if(!auth?.admin){
      navigate("/dashboard");
    }
  }, []);

}