import Header from '../../includes/dashboards/Header';
import ScheduleMenu from './../nav/ScheduleMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { getRequest,  deleteRequest } from '../../../requests/requests';
import Table from "./../../../components/Table";
import { useFetchAuth } from "../../../hooks/auth";
import {useAuthHeader} from 'react-auth-kit'

const Schedules = () => {
  const authHeader = useAuthHeader()

  useFetchAuth();

  const [schedules,setSchedules] = useState([])

  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  useEffect(()=>{
    async function fetchData() {
      const schedules = await getRequest("schedules/", authHeader())
      setSchedules(schedules)
    }
    fetchData()
  },[schedules])

  const onDeleteSchedule = async (id) => {

    return await deleteRequest(`schedules/${id}`, authHeader())

  }

  const schedulesData = (schedules, onDeleteSchedule) => {
    return schedules.map((schedule, i) => ({
      id: i + 1,
      message: schedule.body,
      time: schedule.schedule_time,
      date: schedule.schedule_date.split('T')[0],
      actions: (
        <>
          {/* <Link to={`/edit-schedule/${schedule.id}`} className="btn btn-outline-primary m-1">View</Link> */}
          <button onClick={() => onDeleteSchedule(schedule.message_id)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <ScheduleMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <Table
                        data={schedulesData(schedules, onDeleteSchedule)}
                        columns={["ID", "Message", "Time", "Date", "Actions"]}
                        title="Scheduled Messages"
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                     
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Schedules;
