import Header from '../includes/dashboards/Header';
import DashMenu from './nav/DashMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';

import { useFetchAdminDashboard } from '../../hooks/client/client';
import { useFetchAuth, useCheckClient } from "../../hooks/auth";

const Dashboard = () => {

  useFetchAuth();
  useCheckClient();
  const {  packages, user_packages, sentMessages, failedMessages, activeUsers, inActiveUsers, loading } = useFetchAdminDashboard();

  //Just getting the last package user subcribed to
  let user_package_name; 
  user_packages.map((user_package) => (
    packages.map((packag) => (
      user_package.package_id === packag.id && ( user_package_name = packag.name )
    ))
  ))

  return loading ? (
    <div className="loading-container">
      <div className="m-3">Loading</div>
      <span className="spinner-border spinner-border-sm"></span>
    </div>

    ) : (
  
    <>  
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <DashMenu />

                {/* <!-- partial --> */}

                    <div className="main-panel">
                      <div className="content-wrapper">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="home-tab">
                              <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                                
                              </div>
                              <div className="tab-content tab-content-basic">
                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview"> 
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="statistics-details mb-0">

                                    <div className="row">
                                      <div className="col-sm-3 grid-margin">
                                        <div className="card">
                                          <div className="card-body">
                                            <div>
                                              <p className="card-title card-title-dash font-weight-medium">Total Sent SMS</p>
                                              <h6 className="rate-percentage d-flex justify-content-between">{ sentMessages.length } <span className="text-success text-medium d-flex align-items-center"><i className="mdi mdi-account-check me-2 icon-md"></i></span></h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                        <div className="col-sm-3 grid-margin">
                                          <div className="card">
                                            <div className="card-body">
                                              <div>
                                                <p className="card-title card-title-dash font-weight-medium">Total Failed SMS</p>
                                                <h6 className="rate-percentage d-flex justify-content-between align-items-center">{ failedMessages.length } <span className="text-danger text-medium d-flex align-items-center"><i className="mdi mdi-account-minus me-2 icon-md"></i></span></h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-3 grid-margin">
                                          <div className="card">
                                            <div className="card-body">
                                              <div>
                                                <p className="card-title card-title-dash font-weight-medium">Total Active Users</p>
                                                <h6 className="rate-percentage d-flex justify-content-between">{ activeUsers.length } <span className="text-success text-medium d-flex align-items-center"><i className="mdi mdi-user me-2 icon-md"></i></span></h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-3 grid-margin">
                                          <div className="card">
                                            <div className="card-body">
                                              <div>
                                                <p className="card-title card-title-dash font-weight-medium">Total Inactive Users</p>
                                                <h6 className="rate-percentage  d-flex justify-content-between">{ inActiveUsers.length } <span className="text-danger text-medium d-flex align-items-center"><i className="mdi mdi-ban-box me-2 icon-md "></i></span></h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      

                                    </div>
                                  </div>
                                </div> 
                                  {/* <div className="row">
                                    <div className="col-lg-12 d-flex flex-column">
                                      <div className="row flex-grow">
                                        <div className="col-12 col-lg-4 col-lg-12 grid-margin stretch-card">
                                          <div className="card card-rounded">
                                            <div className="card-body">
                                              <div className="d-sm-flex justify-content-between align-items-start">
                                                <div>
                                                <h4 className="card-title card-title-dash">Performance Line Chart</h4>
                                                <h5 className="card-subtitle card-subtitle-dash">Data taken from the last 30 days</h5>
                                                </div>
                                                <div id="performance-line-legend"></div>
                                              </div>
                                              <div className="chartjs-wrapper mt-4">
                                              <LineChart />
                                                <canvas id="performaneLine"></canvas> 
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- content-wrapper ends --> */}
                      
                  <Footer />

                </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </>
  )
}

export default Dashboard
