import { Link } from "react-router-dom";

const SendScheduledMenu = () => {
  return (
    <>
      {/* <!-- partial:partials/_sidebar.html --> */}
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
           <li className="nav-item">
            <Link className="nav-link" to="/admin/dashboard">
              <i className="mdi mdi-grid-large menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/admin/send-scheduled">
              <i className="mdi mdi-send menu-icon"></i>
              <span className="menu-title">Send Scheduled</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/admin/sender-id/requests">
              <i className="mdi mdi-file-send menu-icon"></i>
              <span className="menu-title">Sender Requests</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/admin/email-marketing">
              <i className="mdi mdi-mail menu-icon"></i>
              <span className="menu-title">Email Marketing</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              data-target="#users"
              aria-expanded="false"
              aria-controls="users"
            >
              <i className="mdi mdi-account menu-icon"></i>
              <span className="menu-title">Users</span>
              <i class="fa fa-chevron-circle-down ml-5" aria-hidden="true"></i>
            </Link>
            <div className="collapse" id="users">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/admin/users">
                    All Users
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/admin/add-user">
                    Add User
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              data-target="#messages"
              aria-expanded="false"
              aria-controls="messages"
            >
              <i className="menu-icon mdi mdi-comment-processing"></i>
              <span className="menu-title">Messages</span>
              <i class="fa fa-chevron-circle-down ml-5" aria-hidden="true"></i>
            </Link>
            <div className="collapse" id="messages">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/admin/messages">
                    All Messages
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/admin/sent-messages">
                    Sent Message
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/admin/failed-messages">
                    Failed Message
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          
          <li className="nav-item">
            <Link className="nav-link" to="/sign-out">
              <i className="mdi mdi-logout menu-icon"></i>
              <span className="menu-title">Sign Out</span>
            </Link>
          </li> 
        </ul>
      </nav>
    </>
  );
};

export default SendScheduledMenu;
