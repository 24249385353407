import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { getHomeRequest } from '../requests/requests';
function scrollToTop() {
    window.scrollTo(0, 0);
  }
  

const Packages = () => {
    const [packages,setpackages] = useState([])

    useEffect(()=>{
        async function fetchData() {
        const packages = await getHomeRequest("home/packages/")
        setpackages(packages)
        }
        fetchData()
    },[packages])

    return (
      <>  
        <Header />
        <Menu />

        {/* <!-- Start Page Title Area --> */}
        <div className="page-title-area item-bg1">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Packages</h2>
                            <ul>
                                <li><Link to="/home" onClick={scrollToTop}>Home</Link>
                                </li>
                                <li>Packages</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Page Title Area --> */}
        
        {/* <!-- Start Pricing Section --> */}
        <section id="price" className="price-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title">
                            <h2>Our Packages</h2>
                        </div>
                    </div>

                    {packages.map((our_package, index) => (

                        <div key={index} className="col-lg-4 col-md-6 price-item">
                            <div className="pricing-content">
                                <div className="price-heading">
                                    <div className="price-name">
                                        <h3>{ our_package.name }</h3>
                                        <span className="price-small-text">R</span>
                                        <h2>{ our_package.price }</h2>
                                        <span className="price-small-text"></span>
                                    </div>
                                </div>
                                <div className="price-body">
                                    <ul>
                                        <li><i className="fa fa-check"></i> { our_package.sms } SMSes</li>
                                        <li><i className="fa fa-check"></i> { our_package.support } Support</li>
                                        <li><i className="fa fa-check"></i> { our_package.sender_ID ? "Custom Sender ID" : "No Custom Sender ID" }</li>
                                        <li><i className="fa fa-check"></i> { our_package.requests } Send Requests</li>
                                        <li><i className="fa fa-check"></i> { our_package.sms_report ? "SMS Reports" : "No SMS Reports" }</li>
                                        <li><i className="fa fa-check"></i> { our_package.subscription_report ? "Subcription Report" : "No Subcription Report" }</li>


                                        {/* <li className="offer-list-none"><i className="fa fa-times"></i> <del>Lifetime Free Updates</del> </li> */}


                                    </ul>
                                </div>
                                <div className="price-btn"> <Link to="/buy-package" className="default-btn" target='_blank' onClick={scrollToTop}>Get Started <span></span></Link> </div>
                            </div>
                        </div>

                    ))}

                </div>
            </div>
        </section>
        {/* <!-- End Pricing Section --> */}

        <Footer />
    </>
  )
}

export default Packages
