import Styles from './Styles';
import face8 from './../images/faces/face8.jpg';
import logo from './../../../images/logo-black.png';
import { Link } from 'react-router-dom';
import React, { useEffect } from "react";
import PerfectScrollbar from 'perfect-scrollbar';
import jQuery from 'jquery';
import { useAuthUser, useIsAuthenticated} from 'react-auth-kit'

const Header = () => {
  const auth = useAuthUser()()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    const toggleOffcanvas = () => {
      const sidebar = document.querySelector(".sidebar-offcanvas");
      sidebar.classList.toggle("active");
    };

    const offcanvasToggle = document.querySelector('[data-bs-toggle="offcanvas"]');
    offcanvasToggle.addEventListener("click", toggleOffcanvas);

    return () => {
      offcanvasToggle.removeEventListener("click", toggleOffcanvas);
    };
  }, []);

 useEffect(() => {
    const body = document.querySelector('body');

    const handleMinimizeClick = () => {
      if ((body.classList.contains('sidebar-toggle-display')) || (body.classList.contains('sidebar-absolute'))) {
        body.classList.toggle('sidebar-hidden');
      } else {
        body.classList.toggle('sidebar-icon-only');
      }
    };
    const minimizeToggle = document.querySelector('[data-bs-toggle="minimize"]');
    minimizeToggle.addEventListener("click", handleMinimizeClick);

    

    const handleWindowScroll = () => {
      document.addEventListener('DOMContentLoaded', () => {
        if(window.matchMedia('(min-width: 992px)').matches) {
          const header = document.querySelector('.horizontal-menu');
          if (window.scrollY >= 70) {
            header.classList.add('fixed-on-scroll');
          } else {
            header.classList.remove('fixed-on-scroll');
          }
        }
      });
    };

  
    window.addEventListener("scroll", handleWindowScroll);

    const datepickerPopup = document.querySelector("#datepicker-popup");
    if (datepickerPopup) {
      const $ = require('jquery');
      require('bootstrap-datepicker');
      $('#datepicker-popup').datepicker({
        enableOnReadonly: true,
        todayHighlight: true,
      });
      $("#datepicker-popup").datepicker("setDate", "0");
    }

    return () => {
      minimizeToggle.removeEventListener("click", handleMinimizeClick);
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);


    return ( 
        <>
        <Styles />
        {/* <div className="row p-0 m-0 proBanner" id="proBanner"> 
          <div className="col-md-12 p-0 m-0">
            <div className="card-body card-body-padding px-3 d-flex align-items-center justify-content-between">
              <div className="ps-lg-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 font-weight-medium me-3 buy-now-text">Please note that the wbsite uses cookies.</p>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <Link to="/"><i className="ti-home me-3 text-white"></i></Link>
                <button id="bannerClose" className="btn border-0 p-0">
                  <i className="ti-close text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </div>  */}
        <div>
          {/* <!-- Required meta tags --> */}
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </div>
          {/* <!-- partial:partials/_navbar.html --> */}
              <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                  <div className="me-3">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize">
                      <span className="icon-menu"></span>
                    </button>
                  </div>
                  <div>
                    <Link className="navbar-brand brand-logo" to="/dashboard">
                      <img src={ logo } alt="logo"  style={{ width: '100%' }}/>
                    </Link>
                    <Link className="navbar-brand brand-logo-mini" to="/dashboard">
                      <img src={ logo } alt="logo"  style={{ width: '100%' }}/>
                    </Link>
                  </div>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-top"> 
                  <ul className="navbar-nav">
                    <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                      <h3 className="welcome-text">Good Day, <span className="text-black fw-bold">{ isAuthenticated ? auth?.name : "" }</span></h3>
                    </li>
                  </ul>
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <form className="search-form" action="#">
                        <i className="icon-search"></i>
                        <input type="search" className="form-control" placeholder="Search Here" title="Search here" />
                      </form>
                    </li>
                    <li className="nav-item dropdown d-none d-lg-block user-dropdown">
                      <Link className="nav-link" id="UserDropdown" to="#" data-bs-toggle="dropdown" aria-expanded="false">
                        <img className="img-xs rounded-circle" src={ face8 } alt="Profile" /> </Link>
                      <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                        <div className="dropdown-header text-center">
                          <img className="img-md rounded-circle" src={ face8 } alt="Profile" />
                        </div>
                        {/* <Link className="dropdown-item"><i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> My Profile <span className="badge badge-pill badge-danger">1</span></Link>
                        <Link className="dropdown-item"><i className="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2"></i> Messages</Link>
                        <Link className="dropdown-item"><i className="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2"></i> Activity</Link> */}
                        <Link to="/profile" className="dropdown-item"><i className="dropdown-item-icon mdi mdi-account text-primary me-2"></i>Profile</Link>
                        <Link to="/change-password" className="dropdown-item"><i className="dropdown-item-icon mdi mdi-lock text-primary me-2"></i>Change Password</Link>
                        <Link to="/sign-out" className="dropdown-item"><i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>Sign Out</Link>
                      </div>
                    </li>
                  </ul>
                  <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-bs-toggle="offcanvas">
                    <span className="mdi mdi-menu"></span>
                  </button>
                </div>
              </nav>
            {/* <!-- partial --> */}
        </> 
    );
}
 
export default Header;