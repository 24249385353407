import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';
function scrollToTop() {
  window.scrollTo(0, 0);
}


const Policies = ({ login_className }) => {
  return (
    <>  
        <Header />
        <Menu login_className = { login_className } />

        {/* <!-- Start Page Title Area --> */}
        <div className="page-title-area item-bg1">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Privacy Policy</h2>
                  <ul>
                    <li><Link to="/home" onClick={scrollToTop}>Home</Link>
                    </li>
                    <li>Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Page Title Area --> */}
        
        {/* <!-- Start Privacy Policy Area --> */}
        <section className="about-area section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content">
                                <div className="about-content-text">
                                  <h2><span className="color-text">Privacy</span> Policies on our bulk SMS</h2>

                                  <p>Our Bulk SMS platform respects the privacy of our customers and is committed to protecting their personal information. This privacy policy explains how we collect, use, and protect personal information when you use our platform.</p>

                                  <p><strong>Information Collection</strong>
                                   <br></br>We collect personal information when you register for an account on our platform, such as your name, email address, and phone number. We also collect information about the messages you send through our platform, including the content of the messages, the recipients' phone numbers, and the date and time the messages were sent.</p> 

                                  <p><strong>Use of Information</strong>
                                    <br></br>We use the personal information we collect to provide and improve our services, such as to verify your identity, to send you notifications about your account and your use of our platform, and to resolve disputes. We also use this information to send you promotional offers and other marketing communications that may be of interest to you.</p> 

                                  <p><strong>Sharing of Information</strong>
                                    <br></br>We do not sell or rent your personal information to third parties. We share your personal information with third parties only in the ways that are described in this privacy policy. We may also share your personal information with third-party service providers who work on our behalf to provide services, such as hosting and maintenance of our platform, to send marketing communications, and to perform other services related to our business.</p> 

                                  <p><strong>Security</strong>
                                    <br></br>We take reasonable steps to protect the personal information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. However, no security measures are perfect or impenetrable, and we cannot guarantee the security of your personal information.</p> 

                                  <p><strong>Changes to This Privacy Policy</strong>
                                    <br></br>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our website. You are advised to review this privacy policy periodically for any changes.</p> 

                                  <p><strong>Contact Us</strong>
                                    <br></br>If you have any questions or concerns about our privacy policy, please contact us at info@innetec.co.za. We will do our best to address your concerns.</p> 

                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- End Privacy Policy Area --> */}

        <Footer />
    </>
  )
}

export default Policies
