import Header from '../../includes/dashboards/Header';
import DashMenu from '../nav/DashMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { getRequest, patchRequest } from '../../../requests/requests';
import { useFetchAuth, useCheckAdmin } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthUser, useAuthHeader } from 'react-auth-kit'

const EditUser = () => {

  const authHeader = useAuthHeader()
  const auth = useAuthUser()()

  useFetchAuth();
  useCheckAdmin();
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [mobile_number, setMobileNumber] = useState('')
  const [email, setEmail] = useState('')
  const [userStatus, setUserStatus] = useState('')

  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    async function fetchData() {
      try {

        const user = await getRequest(`users/${auth?.userID}`, authHeader());
        user.map((user) => {
          setFirstName(user.first_name);
          setLastName(user.last_name);
          setCompany(user.company);
          setMobileNumber(user.mobile_number);
          setEmail(user.email);
          setUserStatus(user.status);
        })

    } catch (error) {
      console.error(error);
      toast.error("Failed to retrieve user information", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
    }

    }
    fetchData()
  },  [auth?.userID])


  const onSubmit = async(e) => {
    e.preventDefault()

    if (!first_name) {
      toast.error('Please add a first name', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!last_name) {
      toast.error('Please add a last name', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!mobile_number) {
      toast.error('Please add a mobile number', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!/^(?!\+)(\b27\d{9}\b)$/.test(mobile_number)) {
      toast.error('Please enter a valid mobile number starting with 27 and exactly 11 digits.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    const status = userStatus

    const data = { first_name, last_name, mobile_number, email, status }

    const updateUser = await patchRequest(`users/${auth?.userID}`, data, authHeader())

    if(updateUser.status === 200){
      setFirstName('')
      setLastName('')
      setMobileNumber('')
      setEmail('')

      toast.success(updateUser.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
    }else{

      toast.error(updateUser.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }


  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <DashMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Edit Profile</h4>
                              <p className="card-description">Mobile number is a required field.</p>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                {company && <h5>Company name: {company}</h5>}
                                <br></br>
                                <div className="form-group">
                                  <label htmlFor="inputFistname1">Fistname *</label>
                                  <input type="text" className="form-control" id="inputFistname1" value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputLastname1">Lastname *</label>
                                  <input type="text" className="form-control" id="inputLastname1" value={last_name} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputMobileNumber1">Mobile Number *</label>
                                  <input type="text" className="form-control" id="inputMobileNumber1" value={mobile_number} onChange={(e) => setMobileNumber(e.target.value)} />
                                  {mobile_number.length > 0 && !/^(?!\+)(\b27\d{9}\b)$/.test(mobile_number) && <p className="alert alert-warning">Please enter a valid mobile number starting with 27 and exactly 11 digits.</p>}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputEmail1">Email</label>
                                  <input type="email" className="form-control" id="inputEmail1" value={email} disabled />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputStatus1">Status</label>

                                  <select className="form-control form-control-lg" id="inputStatus1" onChange={(e) => setUserStatus(e.target.value)} defaultValue={userStatus}>
                                    <React.Fragment>
                                      {
                                        userStatus === "9356" && (
                                          <React.Fragment>
                                            <option value={userStatus}>Active</option>
                                            <option value="0">Deactivate</option>
                                          </React.Fragment>
                                        )
                                      }
                                      {
                                        userStatus !== "9356" && (
                                          <React.Fragment>
                                            <option value={userStatus}>Deactivated</option>
                                            <option value="9356">Activate</option>
                                          </React.Fragment>
                                        )
                                      }
                                    </React.Fragment>
                                  </select>


                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Update Profile</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default EditUser
