import axios from "axios";
const API_URL = "https://premiumsms.co.za/api/";
// const API_URL = "http://localhost:5000/";

const register = (first_name, last_name, company, mobile_number, email, password, type) => {
  return axios.post(API_URL + "auth/register", {
    first_name,
    last_name,
    company,
    mobile_number,
    email,
    password,
    type,
  });
};

const login = async (email, password) => {
  try {
    const response = await axios.post(API_URL + "auth/login", {
      email,
      password,
    });

    if (response.data.accessToken) {
      
    }

    return response.data;

  } catch (error) {
    console.error("An error occurred during login:", error);
    throw error;
  }
};

const logout = async () => {
  try {
    await axios.post(API_URL + "auth/logout");
    window.location.href = "/sign-in";
  } catch (error) {
    console.error(error);
    // alert("Logout failed. Please try again.");
  }
};

const AuthService = {
  register,
  login,
  logout,
};

export default AuthService;
