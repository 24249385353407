import Header from '../../includes/dashboards/Header';
import SendScheduledMenu from '../nav/SendScheduledMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState } from "react";
import { postRequest } from '../../../requests/requests';
import { useFetchAuth, useCheckClient } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthHeader } from 'react-auth-kit'

const Send = () => {

  const authHeader = useAuthHeader()

  useFetchAuth();
  useCheckClient();
  const [schedule_time, setTime] = useState('')
  const [schedule_date, setDate] = useState('')
  const [loading, setLoading] = useState(false)

  //handle event when ever the option is selected.
  const handleChange2 = (event) => {
    setTime(Array.from(event.target.selectedOptions, (option) => option.value));
  };
  

  const onSubmit = async(e) => {
    e.preventDefault()

    if (!schedule_time) {
      toast.error('Please select schedule time', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!schedule_date) {
      toast.error('Please select schedule date', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }
    const message_type = "SMS"
    const data = { schedule_time, schedule_date, message_type }

    const sendScheduled = await postRequest(`messages/scheduled/send`, data, authHeader())

    if(sendScheduled.status === 200){
      setTime('')
      setDate('')

      toast.success('Scheduled Messages Were Sent!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
    }else{

      toast.error(sendScheduled.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }

  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <SendScheduledMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Send Scheduled Messages</h4>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                <div className="form-group">
                                  <label htmlFor="inputScheduleTime1">Schedule Time</label>
                                  <select className="form-control form-control-lg" id="inputScheduleTime1" multiple onChange={handleChange2}>
                                    <option value="9AM">9:00 AM</option>
                                    <option value="12PM">12:00 PM</option>
                                    <option value="3PM">3:00 PM</option>
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputScheduleDate1">Schedule Date</label>
                                  <input type="date" className="form-control" id="inputScheduleDate1" value={schedule_date} onChange={(e) => setDate(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Send Scheduled</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Send
