import Header from '../includes/dashboards/Header';
import SenderIDRequestMenu from './nav/SenderIDRequestMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';
import { patchRequest } from '../../requests/requests';
import { useFetchSenderIDRequests } from '../../hooks/client/client';
import { useFetchAuth, useCheckClient } from "../../hooks/auth";
import {useAuthHeader} from 'react-auth-kit'

const SenderIDRequests = () => {
  const authHeader = useAuthHeader()

  useFetchAuth();
  useCheckClient();
  const { requests } = useFetchSenderIDRequests();

  // Approve SenderID
  const ApproveSenderID = async (id, sender) => {

    const user = { "requested_sender_ID_status":1, "sender_ID":sender }
    await patchRequest(`users/requests/${id}`, user, authHeader())

  }

  // Reject SenderID
  const RejectSenderID = async (id, sender) => {

    const user = { "requested_sender_ID_status":-1, "sender_ID":sender }
    await patchRequest(`users/requests/${id}`, user, authHeader())

  }

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <SenderIDRequestMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                      <div className="content-wrapper">
                      <div className="card">
                          <div className="card-body">
                            <h4 className="card-title">Sender ID Requests</h4>
                            <div className="row">
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table id="order-listing" className="table">
                                    <thead>
                                      <tr>
                                          <th> # </th>
                                          <th>Requested By</th>
                                          <th>Mobile Number</th>
                                          <th>Requested Sender ID</th>
                                          <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {requests.map((request, index) => (
                                        <tr key={index}>
                                            <td>{ index + 1 }</td>
                                            <td>{ request.first_name } { request.last_name }</td>
                                            <td>{ request.mobile_number }</td>
                                            <td>{ request.requested_sender_ID }</td>
                                            <td>
                                            <button onClick={() => ApproveSenderID(request.id, request.requested_sender_ID)} className="btn btn-outline-success border border-success m-1">Approve ID</button>
                                              <button onClick={() => RejectSenderID(request.id, request.sender_ID)} className="btn btn-outline-danger border border-danger m-1">Reject ID</button>
                                            </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default SenderIDRequests;
