import { Link } from "react-router-dom";

const BillingMenu = () => {
  return (
    <>
      {/* <!-- partial:partials/_sidebar.html --> */}
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-grid-large menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/send-sms">
              <i className="mdi mdi-send menu-icon"></i>
              <span className="menu-title">Send Message</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/buy-package">
              <i className="mdi mdi-package menu-icon"></i>
              <span className="menu-title">Buy Package</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/sender-id">
              <i className="mdi mdi-file-send menu-icon"></i>
              <span className="menu-title">Sender ID</span>
            </Link>
          </li>
          
          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              data-target="#contacts"
              aria-expanded="false"
              aria-controls="contacts"
            >
              <i className="mdi mdi-account menu-icon"></i>
              <span className="menu-title mr-1">Contacts</span>
              <i class="fa fa-chevron-circle-down ml-5" aria-hidden="true"></i>
            </Link>
            <div className="collapse" id="contacts">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/contacts">
                    All Contacts
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/add-contact">
                    Add Contact
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              data-target="#groups"
              aria-expanded="false"
              aria-controls="groups"
            >
              <i className="menu-icon mdi mdi-account-multiple"></i>
              <span className="menu-title mr-3">Groups</span>
              <i class="fa fa-chevron-circle-down ml-5" aria-hidden="true"></i>
            </Link>
            <div className="collapse" id="groups">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/groups">
                    All Groups
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/add-group">
                    Add Group
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              data-target="#messages"
              aria-expanded="false"
              aria-controls="messages"
            >
              <i className="menu-icon mdi mdi-comment-processing"></i>
              <span className="menu-title">Messages</span>
              <i class="fa fa-chevron-circle-down ml-5" aria-hidden="true"></i>
            </Link>
            <div className="collapse" id="messages">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/messages">
                    All Messages
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/sent-messages">
                    Sent Message
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/failed-messages">
                    Failed Message
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              data-target="#schedules"
              aria-expanded="false"
              aria-controls="schedules"
            >
              <i className="menu-icon mdi mdi-comment-processing"></i>
              <span className="menu-title">Schedules</span>
              <i class="fa fa-chevron-circle-down ml-5" aria-hidden="true"></i>
            </Link>
            <div className="collapse" id="schedules">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/schedules">
                    All Schedules
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/add-schedule">
                    Add Schedule
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              data-target="#templates"
              aria-expanded="false"
              aria-controls="templates"
            >
              <i className="menu-icon mdi mdi-tooltip-text"></i>
              <span className="menu-title">Templates</span>
              <i class="fa fa-chevron-circle-down ml-5" aria-hidden="true"></i>
            </Link>
            <div className="collapse" id="templates">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/templates">
                    All Templates
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/add-template">
                    Add Template
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/billing">
              <i className="mdi mdi-cash-multiple menu-icon"></i>
              <span className="menu-title">Billing</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/sign-out">
              <i className="mdi mdi-logout menu-icon"></i>
              <span className="menu-title">Sign Out</span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default BillingMenu;
