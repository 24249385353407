const API_BASE_URL = 'https://premiumsms.co.za/api/';
// const API_BASE_URL = "http://localhost:5000/";

// Get request
export const getRequest = async (endpoint, token) => {
  const res = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'Authorization': token
    },
  })

  // if (res.status === 401) {
  //   window.location.href = '/sign-out';
  // }

  const data = await res.json()

  return data
}

// Get home request - This are the ones without auth
export const getHomeRequest = async (endpoint) => {
  const res = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  })

  const data = await res.json()

  return data
}

// Post request
export const postRequest = async (endpoint, body, token) => {
  const res = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(body),
  })

  // if (res.status === 401) {
  //   window.location.href = '/sign-out';
  // }

  const data = await res.json()

  return data
}

// Patch request
export const patchRequest = async (endpoint, body, token) => {
  const res = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: 'PATCH',
    headers: {
      'Content-type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(body),
  })

  // if (res.status === 401) {
  //   window.location.href = '/sign-out';
  // }

  const data = await res.json()

  return data
}

// Delete Request
export const deleteRequest = async (endpoint, token) => {
  const res = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      'Authorization': token
    },
  })

  // if (res.status === 401) {
  //   window.location.href = '/sign-out';
  // }

  //We should control the response status to decide if we will change the state or not.
  //res.status === 200 ? return true : return false
}
