import Header from '../../includes/dashboards/Header';
import ContactMenu from './../nav/ContactMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import Papa from 'papaparse';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthHeader} from 'react-auth-kit'

const AddContact = () => {

  const authHeader = useAuthHeader()

  useFetchAuth();

  const [groups,setGroups] = useState([])
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [mobile_number, setMobileNumber] = useState('')
  const [email, setEmail] = useState('')
  const [group_id, setGroupID] = useState([])
  const [fileData, setFileData] = useState({});
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    async function fetchData() {
      const groups = await getRequest("groups/", authHeader())
      setGroups(groups)
    }
    fetchData()
  })

  
  //handle event when ever the option is selected.
  const handleChange = (event) => {
    setGroupID(Array.from(event.target.selectedOptions, (option) => option.value));
  };

  const handleCSVFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const result = Papa.parse(event.target.result, {
        header: true, // assume the first row is the header row
      });
      setFileData(result.data);
    };
  };

  const handleBulkUpload = async (e) => {

    e.preventDefault()

    setLoading(true);

    if(fileData.length){
      await Promise.all(fileData.map(async (file) => {
        if (file.Name !== "") {
          const data = {
            first_name: file.Name,
            last_name: file.Surname,
            company: file.Organization,
            mobile_number: file.Mobile,
            email: file.Email,
            group_id: group_id
          };
          try {
            const getContactMobile = await getRequest(
              `contacts/mobile/${file.Mobile}`, authHeader()
            );

            if (getContactMobile.length < 1) {
              const addContact = await postRequest(`contacts/`, data, authHeader());
              console.log(`Added contact ${file.Name}`);
              if(addContact){
          
                console.log(`Contact added as ${file.Name}`);
          
              }
            }
          } catch (error) {
            console.error(`Error adding contact ${file.Name}:`, error);
          }
        }
      }));
    }else{
      toast.error('Please select a csv file with data', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    toast.success("Bulk contacts added!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000
    });
    setLoading(false);

  }
  

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!first_name) {
      toast.error('Please add a first name', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    // if (!last_name) {
    //   toast.error('Please add a last name', {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 5000
    //   });
    //   return
    // }

    if (!mobile_number) {
      toast.error('Please add a mobile number', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!/^(?!\+)(\b27\d{9}\b)$/.test(mobile_number)) {
      toast.error('Please enter a valid mobile number starting with 27 and exactly 11 digits.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    const getContactMobile = await getRequest(
      `contacts/mobile/${mobile_number}`, authHeader()
    );

    if (getContactMobile.length < 1) {

    const data = { first_name, last_name, company, mobile_number, email, group_id }

    const addContact = await postRequest(`contacts/`, data, authHeader())

    if(addContact.status === 200){

      setFirstName('')
      setLastName('')
      setMobileNumber('')
      setEmail('')

      toast.success(addContact.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);

    }else{

      toast.error(addContact.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
  }else{

    toast.error('Mobile number already exists!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000
    });
    return

  }

    
  }

  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <ContactMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Add Contact</h4>
                              <p className="card-description">Mobile number is a required field.</p>

                              <form className="forms-sample">

                                <div className="form-group">
                                  <label htmlFor="inputCSV1">Upload bulk from csv?</label>
                                  <input type="file" className="form-control" id="inputCSV1" accept=".csv" onChange={handleCSVFileChange} />
                                  {/* {fileData && <p>Selected file: {fileData.name}</p>} */}
                                </div> 

                                <button type="submit" className="btn btn-primary me-2" onClick={handleBulkUpload} disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Add Bulk Contacts</span>
                                  </button>
                              </form>

                              <p className='mt-3'><strong>OR</strong></p>

                              <form className="forms-sample mt-3" onSubmit={onSubmit}>

                                <div className="form-group">
                                  <label htmlFor="inputFistname1">Fistname *</label>
                                  <input type="text" className="form-control" id="inputFistname1" value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputLastname1">Lastname </label>
                                  <input type="text" className="form-control" id="inputLastname1" value={last_name} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputOrganization1">Organization </label>
                                  <input type="text" className="form-control" id="inputOrganization1" value={company} onChange={(e) => setCompany(e.target.value)} />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="inputMobileNumber1">Mobile Number *</label>
                                  <input type="text" className="form-control" id="inputMobileNumber1" value={mobile_number} onChange={(e) => setMobileNumber(e.target.value)} />
                                  {mobile_number.length > 0 && !/^(?!\+)(\b27\d{9}\b)$/.test(mobile_number) && <p className="alert alert-warning">Please enter a valid mobile number starting with 27 and exactly 11 digits.</p>}
                                </div>


                                <div className="form-group">
                                  <label htmlFor="inputEmail1">Email</label>
                                  <input type="email" className="form-control" id="inputEmail1" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputGroups1">Groups(Optional)</label>
                                  <select className="form-control form-control-lg" id="inputGroups1" multiple onChange={handleChange} size="10">
                                    {groups.map((group, index) => (
                                      <option key={index} value={ group.id }>{ group.name }</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Add Contact</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default AddContact
