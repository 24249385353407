import Header from "../includes/dashboards/Header";
import SMSMenu from "./nav/SMSMenu";
import Footer from "../includes/dashboards/Footer";
import Theme from "../includes/dashboards/Theme";
import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../requests/requests";
import { useNavigate } from "react-router-dom";
import { useFetchAuth } from "../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from "papaparse";
import { Link } from 'react-router-dom';
import {useAuthHeader} from 'react-auth-kit'

const SendSMS = () => {
  const authHeader = useAuthHeader()
  let navigate = useNavigate();

  useFetchAuth();

  const [myContacts, setMyContacts] = useState([]);
  const [shortMessage, setShortMessage] = useState("");
  const [messageType] = useState("SMS");
  const [group, setGroupID] = useState("");

  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState([]);
  const [contact_group, setContactsGroup] = useState([]);
  const [contact_csv, setContactsCSV] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [groups, setGroups] = useState([]);
  const [charactersLeft, setCharactersLeft] = useState(149);

  useEffect(() => {
    async function fetchData() {
      const contacts = await getRequest("contacts/", authHeader());
      const contact_group = await getRequest("groups/contactGroup/", authHeader());
      const templates = await getRequest("templates/", authHeader());
      const groups = await getRequest("groups/", authHeader());
      const templateBody = JSON.parse(localStorage.getItem("templateBody"));
      const groupId = JSON.parse(localStorage.getItem("groupId"));

      setContacts(contacts);
      setContactsGroup(contact_group);
      setTemplates(templates);
      setGroups(groups);
      setGroupID(groupId);

      if(setShortMessage(templateBody)){
        clearSession()
      }
      
    }
    fetchData();
  }, []);

  const handleCSVFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (event) => {
      const delimiters = [",", ";", "\t", "|", ":", " "]; // Array of delimiters to check
  
      let result = null;
      let delimiterUsed = null;
  
      for (let delimiter of delimiters) {
        const fileContent = event.target.result;
        const modifiedContent = fileContent.replace(/;/g, ","); // Replace semicolons with commas
        result = Papa.parse(modifiedContent, {
          header: true, // assume the first row is the header row
          delimiter: delimiter,
        });
  
        // Check if the parsing was successful
        if (result && result.data && result.data.length > 0) {
          delimiterUsed = delimiter;
          break;
        }
      }
  
      if (!delimiterUsed) {
        alert("Unable to determine the delimiter used in the file.");
        return;
      }
  
      let cont_csv = [];
      let cont_csv_mobile = myContacts;
  
      let availableContacts = contacts;
  
      await Promise.all(
        result.data.map(async (file) => {
          if (file.Name.trim() !== "") {
            const data = {
              first_name: file.Name,
              last_name: file.Surname,
              company: file.Organization,
              mobile_number: file.Mobile,
              email: file.Email,
              group_id: [],
            };
  
            const changingData = {
              activity: file.Activity,
              time: file.Time,
              amount: file.Amount,
            };
  
            if (!/^(?!\+)(\b27\d{9}\b)$/.test(file.Mobile)) {
              alert(
                "Please enter a valid mobile number starting with 27 and exactly 11 digits for " +
                  file.Name +
                  " " +
                  file.Surname +
                  " => " +
                  file.Mobile
              );
              return;
            }
  
            try {
              let getContactMobile = await getRequest(
                `contacts/mobile/${file.Mobile}`, authHeader()
              );
  
              if (getContactMobile.length < 1) {
                await postRequest(`contacts/`, data, authHeader());
                console.log(`Added contact ${file.Name}`);
  
                getContactMobile = await getRequest(
                  `contacts/mobile/${file.Mobile}`, authHeader()
                );
  
                //We add this contact to available contacts
                availableContacts.push(getContactMobile[0]);
              }
              setContacts(availableContacts);
              cont_csv.push({ ...getContactMobile, changingData });
              cont_csv_mobile.push(getContactMobile[0].mobile_number);
            } catch (error) {
              console.error(`Error adding contact ${file.Name}:`, error);
            }
          }
        })
      );
  
      setContactsCSV(cont_csv);
      setMyContacts(cont_csv_mobile);
  
      // call handleChange function here
      // handleChange(event);
    };
  };

  
  const clearSession = () =>{
    const itemsToRemove = ['templateBody', 'groupId', 'myContactz', 'contact_csv', 'shortMessag']
    // const itemsToRemove = ["templateBody"];
    itemsToRemove.forEach((item) => localStorage.removeItem(item));
  }

  //handle event when ever the option is selected.
  const handleChange = (event) => {
    setMyContacts(
      Array.from(event.target.selectedOptions, (option) => option.value)
    );
  };

  //handle event when ever the option is selected.
  const handleChange1 = (event) => {
    event.preventDefault();
    //In this function, we update change in groupId, we must also update the contacts ids as well
    const temp_group_id = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );

    setGroupID(temp_group_id);
    
  };

  const setMyContactsFunc = (group) =>{

    if(group > 0){

      let cont_mobile = myContacts;

      contacts.map((contact, index) => {
        //When we selecting from a group we use this loop and condition
        contact_group.forEach((contactGroup) => {
          if (
            contactGroup.contact_id == contact.id &&
            contactGroup.group_id == group
          ) {
            cont_mobile.push(contact.mobile_number);
          }
        });
      });

      setMyContacts(cont_mobile);

      }

  }

  //handle event when ever the option is selected.
  const handleChange3 = (event) => {
    event.preventDefault();
    const selectedOption = event.target.selectedOptions[0];
    if (selectedOption) {
      setShortMessage(selectedOption.value);
    }
  };
  

  //handle event when ever the option is selected.
  const handleClearContacts = (event) => {
    event.preventDefault();
    localStorage.setItem("templateBody", JSON.stringify(shortMessage));
    window.location.reload();
  };

  //Submitiing message for sending
  const handleSubmit = async (event) => {
    event.preventDefault();

    setMyContactsFunc(group)

    if (myContacts.length < 1) {
      toast.error('Please select contacts', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (!shortMessage) {
      toast.error('Please add a message', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (shortMessage.length > 149) {
      toast.error('Message must not be more than 149 characters', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (!messageType) {
      toast.error('Please add a message type', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    const message = {
      my_contacts: myContacts,
      contact_csv: contact_csv,
      short_message: shortMessage,
      message_type: messageType,
    };

    const sendMessage = await postRequest(`messages`, message, authHeader());

    // const sendMessage = {
    //   "status": 200,
    //   "message": "Message(s) sent"
    // }

    if (sendMessage.status === 200) {
      clearSession()
      setShortMessage("");
      toast.success(sendMessage.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
    } else {
      toast.error(
      <div>
        {sendMessage.message}
        {sendMessage.message === "You do not have enough SMS credit!" && (
          <div>
            <br></br>
            Click <a href="https://premiumsms.co.za/buy-package">here</a> to buy SMSes.
          </div>
        )}
      </div>,
        
        {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        closeButton: true
      });
      setLoading(false);
    }
  };

  const handleTemplate = async (event) => {
    event.preventDefault();

    setMyContactsFunc(group)

    if (myContacts.length < 1) {
      toast.error('Please select contacts', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false
      });
      return;
    }

    if (!shortMessage) {
      toast.error('Please add a message', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (shortMessage.length > 160) {
      toast.error('Message must not be more than 149 characters', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (!messageType) {
      toast.error('Please add a message type', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    const message = {
      my_contacts: myContacts,
      contact_csv: contact_csv,
      short_message: shortMessage,
      message_type: messageType,
    };

    const sendMessage = await postRequest(`messages/`, message, authHeader());

    if (sendMessage) {
      const template = { body: shortMessage };

      const addTemplate = await postRequest(`templates/`, template, authHeader());

      if (addTemplate.status === 200 && sendMessage.status === 200) {
        clearSession()
        const toastMessages = ["Message(s) sent successful!", "Tempate added successful"];

          toastMessages.forEach(message => {
            toast.success(
              <div>
                {message}
                {sendMessage.message === "You do not have enough SMS credit!" && (
                  <div>
                    <br></br>
                    Click <a href="https://premiumsms.co.za/buy-package">here</a> to buy SMSes.
                  </div>
                )}
              </div>,
              {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000
            });
          });
        setLoading(false);
      } else {
        if (sendMessage.status === 200) {
          const toastMessages = ["Message(s) sent successful!", "Tempate not added successful"];

          toastMessages.forEach(message => {
            toast.info(
              <div>
                {message}
                {sendMessage.message === "You do not have enough SMS credit!" && (
                  <div>
                    <br></br>
                    Click <a href="https://premiumsms.co.za/buy-package">here</a> to buy SMSes.
                  </div>
                )}
              </div>,
              {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000
            });
          });
        } else {

          const toastMessages = ["Message(s) not sent successful!", "Tempate added successful"];

          toastMessages.forEach(message => {
            toast.info(
            <div>
              {message}
              {sendMessage.message === "You do not have enough SMS credit!" && (
                <div>
                  <br></br>
                  Click <a href="https://premiumsms.co.za/buy-package">here</a> to buy SMSes.
                </div>
              )}
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000
            });
          });

        }

        setLoading(false);
      }
    }
  };

  const handleSchedule = async (event) => {
    event.preventDefault();

    setMyContactsFunc(group)

    if (myContacts.length < 1) {
      toast.error('Please select contacts', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false
      });
      return;
    }

    if (!shortMessage) {
      toast.error('Please add a message', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (shortMessage.length > 149) {
      toast.error('Message must not be more than 149 characters', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (!messageType) {
      toast.error('Please add a message type', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    localStorage.setItem("myContactz", JSON.stringify(myContacts));
    localStorage.setItem("contact_csv", JSON.stringify(contact_csv));
    localStorage.setItem("shortMessag", JSON.stringify(shortMessage));

    navigate("/add-schedule");
    window.location.reload();
  };
  
  const handleBodyChange = (e) => {
    const value = e.target.value;

    if (value.length <= 149) {
      setShortMessage(value);
      setCharactersLeft(149 - value.length);
    } else {
      setShortMessage(value.slice(0, 149));
    }
  }; 

  return (
    <div>
      <ToastContainer />
      <div className="with-welcome-text">
        <div className="container-scroller">
          <Header />
          {/* <!-- partial --> */}
          <div className="container-fluid page-body-wrapper">
            <Theme />

            <SMSMenu />

            {/* <!-- partial --> */}

            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="Col-lg-12 row">
                          <div className="Col-lg-6">
                            <h4 className="card-title">Send SMS</h4>
                            <p className="card-description">
                              Please make sure that you write a short message
                              (149 characters long)<br></br>
                              <strong>Dynamic fields, use [name] for name, [surname] for surname, [organization] for organization, [mobile] for  mobile, [email] for email</strong> <br></br>
                              <strong>CSV Dynamic fields, use [name] for name, [surname] for surname, [organization] for organization, [mobile] for  mobile, [email] for email, [activity] for activity, [time] for time, [amount] for amount</strong>
                            </p>
                          </div>
                        </div>
                        <form className="forms-sample">
                          <div className="form-group">
                            <label htmlFor="inputGroups1">
                              Send To Group(Optional)
                            </label>
                            <select
                              className="form-control form-control-lg"
                              id="inputGroups1"
                              onChange={handleChange1}
                              defaultValue={"No Group selected"}
                            >
                              <option value="">Select Group</option>
                              {groups.map((groupz, index) => (
                                <option key={index} value={groupz.id}>
                                  {groupz.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="inputCSV1">
                              Upload bulk from csv?
                            </label><br></br>
                            <a href="https://premiumsms.co.za/contacts.csv" target="_blank">GET THE CSV FORMAT</a>
                            <br></br>
                            <input
                              type="file"
                              className="form-control"
                              id="inputCSV1"
                              accept=".csv"
                              onChange={handleCSVFileChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputContacts1">Contacts</label>
                            <label
                              onClick={handleClearContacts}
                              class="mx-3 btn btn-primary"
                            >
                              <span className="mdi mdi-reload"></span> Refresh
                              Contacts
                            </label>
                            {/* onChange={(e) => setContacts(e.target.value)} */}
                            <select
                              className="form-control form-control-lg"
                              defaultValue={[]}
                              id="inputContacts1"
                              multiple
                              onChange={handleChange}
                              size="10"
                            >
                              {contacts.map((contact, index) => {
                                let isSelected = false;

                                //When we selecting from a group we use this loop and condition
                                contact_group.forEach((contactGroup) => {
                                  if (
                                    contactGroup.contact_id == contact.id &&
                                    contactGroup.group_id == group
                                  ) {
                                    isSelected = true;
                                    return;
                                  }
                                });

                                // When we selecting from a group we use this loop condition
                                contact_csv.forEach((contactCSV) => {
                                  if (contactCSV[0].id == contact.id) {
                                    isSelected = true;
                                    return;
                                  }
                                });

                                return (
                                  <option
                                    key={index}
                                    value={contact.mobile_number}
                                    selected={isSelected}
                                  >
                                    {contact.first_name}({contact.mobile_number}
                                    ) {contact.company}
                                  </option>
                                );
                              })}
                            </select>

                            {/* <Select className="basic-multi-select" classNamePrefix="select" isMulti options={contactOptions} /> */}
                          </div>

                          <div className="form-group">
                            <label htmlFor="inputTemplates1">
                              Select Template(Optional)
                            </label>
                            <select
                              className="form-control form-control-lg"
                              id="inputTemplates1"
                              onChange={handleChange3}
                              defaultValue=""
                            >
                              <option value="">Select Template</option>
                              {templates.map((template, index) => (
                                <option key={index} value={template.body}>
                                  {template.body}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="inputMessage1">Message (149 Characters)</label>
                            <p className={`${charactersLeft > 30 ? 'text-green' : 'text-red'}`}>{charactersLeft} Characters Left</p>
                            <textarea type="text" className="form-control" id="inputMessage1" value={shortMessage} onChange={handleBodyChange} cols="10" rows="15"
                              maxLength={149} >{ shortMessage }</textarea>
                          </div>

                          {/* <div className="form-group">
                                  <label htmlFor="inputContacts1">Message Type</label>

                                  <select className="form-control form-control-lg" id="inputMessageType1" onChange={(e) => setMessageType(e.target.value)} defaultValue={'No Message selected'}>
                                    <option value="">Select Message Type</option>
                                    <option value="SMS">SMS</option>
                                    <option value="WhatsApp">WhatsApp</option>
                                    <option value="Email">Email</option>
                                  </select>

                                </div> */}

                          <button
                            type="submit"
                            className="btn btn-primary m-2"
                            onClick={handleSubmit}
                            disabled={loading}
                          >
                            {loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Send Message
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary m-2"
                            onClick={handleTemplate}
                            disabled={loading}
                          >
                            {loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Send & save template
                          </button>
                          {/* <button
                            type="submit"
                            className="btn btn-primary m-2"
                            onClick={handleSchedule}
                            disabled={loading}
                          >
                            {loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Schedule Message
                          </button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>

            {/* <!-- main-panel ends --> */}
          </div>

          {/* <!-- page-body-wrapper ends --> */}
        </div>

        {/* <!-- container-scroller --> */}
      </div>
    </div>
  );
};

export default SendSMS;
