import { useFetchSignOut } from '../hooks/client/client';

const SignOut = () => {

  useFetchSignOut();

  return (
    <> 
    </>
  )
}

export default SignOut
