import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';

function scrollToTop() {
  window.scrollTo(0, 0);
}


const UseCases = ({ login_className }) => {
  return (
    <>  
        <Header />
        <Menu login_className = { login_className } />

        {/* <!-- Start Page Title Area --> */}
        <div className="page-title-area item-bg1">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Use Cases</h2>
                  <ul>
                    <li><Link to="/home" onClick={scrollToTop}>Home</Link>
                    </li>
                    <li>Use Cases</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Page Title Area --> */}
        
        {/* <!-- Start Privacy Policy Area --> */}
        <section className="about-area section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content">
                                <div className="about-content-text">
                                  <h2><span className="color-text">Use</span> cases of our bulk SMS</h2>
                                  <p>Bulk SMS, also known as mass SMS or bulk text messaging, is a way to send a large number of SMS messages to multiple recipients at once. Here are a few use cases for our bulk SMS:</p>

                                   <p>
                                    <ol>
                                        <li><strong>Marketing campaigns:</strong> Businesses can use ou bulk SMS to send promotional offers, discounts, and special deals to customers.</li>
                                        <li><strong>Event notifications:</strong> Event organizers can use our bulk SMS to send reminders and updates about upcoming events to attendees.</li>
                                        <li><strong>Appointment reminders:</strong> Medical clinics and other service providers can use our bulk SMS to send appointment reminders to patients or customers.</li>
                                        <li><strong>Emergency notifications:</strong> Schools, hospitals, and other organizations can use our bulk SMS to send emergency notifications to staff and students.</li>
                                        <li><strong>Payment reminders:</strong> Banks and other financial institutions can use our bulk SMS to send payment reminders to customers.</li>
                                        <li><strong>Political campaigns:</strong> Political campaigns can use  our bulk SMS to send updates, reminders and news to the voters.</li>
                                        <li><strong>Surveys and feedback:</strong> Companies can use our bulk SMS to conduct surveys and gather feedback from customers.</li>
                                        <li><strong>Order and shipping updates:</strong> E-commerce businesses can use our bulk SMS to send order and shipping updates to customers.</li>
                                        <li><strong>Network outages:</strong> ISPs and other network providers can use our bulk SMS to send outages and maintenance notifications to their customers.</li>
                                        <li><strong>OTP and Verification:</strong> Many services providers use our bulk SMS to send OTP and Verification messages to their users.</li>
                                      </ol>
                                    </p> 

                                  <p>These are just a few examples of how our bulk SMS can be used. The potential use cases are vast and varied, and can be tailored to the specific needs of any business or organization.</p>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- End Privacy Policy Area --> */}

        <Footer />
    </>
  )
}

export default UseCases
