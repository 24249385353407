import Header from '../../includes/dashboards/Header';
import ScheduleMenu from '../nav/ScheduleMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getRequest, patchRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthHeader} from 'react-auth-kit'

const EditSchedule = () => {
  const authHeader = useAuthHeader()

  const {id} = useParams(); // getting url id

  useFetchAuth();

  const [schedule_time, setTime] = useState('')
  const [schedule_date, setDate] = useState('')

  const [loading, setLoading] = useState(false)
  const [charactersLeft, setCharactersLeft] = useState(149);

  useEffect(()=>{
    async function fetchData() {
      const schedule = await getRequest(`schedules/${id}`, authHeader());
      schedule.map((schedule) => {
        setTime( schedule.schedule_time )
        setDate( schedule.schedule_date )
      })

    }
    fetchData()
  },[])

  const onSubmit = async(e) => {
    e.preventDefault()

    if (!schedule_time) {
      toast.error('Please add a schedule time', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!schedule_date) {
      toast.error('Please add a schedule date', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    const data = { schedule_time, schedule_date }

    const updateSchedule = await patchRequest(`schedules/${id}`, data, authHeader())

    if(updateSchedule.status === 200){
      setTime('')
      setDate('')

      toast.success(updateSchedule.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);

    }else{

      toast.error(updateSchedule.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }

  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <ScheduleMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Edit Schedule</h4>
                              <p className="card-description">
                                Please make sure that you write a short message
                                (149 characters long)<br></br>
                                <strong>Dynamic fields, use [name] for name, [surname] for surname, [mobile] for  mobile, [email] for email</strong> <br></br>
                                <strong>CSV Dynamic fields, use [name] for name, [activity] for activity, [time] for time, [amount] for amount</strong>
                              </p>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                <div className="form-group">
                                  <label htmlFor="inputMessage1">Message</label>
                                  <textarea type="text" className="form-control" id="inputMessage1" cols="10" rows="15"></textarea>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputScheduleTime1">Schedule Time</label>
                                  <input type="date" className="form-control" id="inputScheduleTime1" value={schedule_time} onChange={(e) => setTime(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputScheduleDate1">Schedule Date</label>
                                  <input type="date" className="form-control" id="inputScheduleDate1" value={schedule_date} onChange={(e) => setDate(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Update Schedule</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default EditSchedule
