import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';

function scrollToTop() {
  window.scrollTo(0, 0);
}

const Why = ({ login_className }) => {
  return (
    <>  
        <Header />
        <Menu login_className = { login_className } />

        {/* <!-- Start Page Title Area --> */}
        <div className="page-title-area item-bg1">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Why SMS</h2>
                  <ul>
                    <li><Link to="/home" onClick={scrollToTop}>Home</Link>
                    </li>
                    <li>Why SMS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Page Title Area --> */}
        
        {/* <!-- Start Privacy Policy Area --> */}
        <section className="about-area section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content">
                                <div className="about-content-text">
                                  <h2><span className="color-text">Why</span> our bulk SMS?</h2>
                                  <p>There are several reasons why our bulk SMS is an effective communication tool for businesses and organizations:</p>

                                   <p>
                                    <ul>
                                        <li><strong>High open rate:</strong> SMS messages have a high open rate compared to other forms of communication, such as email.</li>
                                        <li><strong>Immediate delivery:</strong> SMS messages are delivered almost instantly, making it an ideal tool for time-sensitive information such as appointment reminders, emergency notifications, and order updates.</li>
                                        <li><strong>Cost-effective:</strong> Sending bulk SMS messages is relatively inexpensive compared to other forms of communication, such as direct mail or telemarketing.</li>
                                        <li><strong>Targeted:</strong> You can send SMS messages to specific groups of people, such as customers who have opted-in to receive text messages from your business.</li>
                                        <li><strong>Customizable:</strong> You can personalize SMS messages with the recipient's name, location or other relevant information.</li>
                                        <li><strong>Measurable:</strong> You can track the delivery rate, open rate, click-through rate of your SMS campaigns and make adjustments accordingly.</li>
                                        <li><strong>Easy to use:</strong> Our bulk SMS service is easy-to-use for sending and managing bulk SMS messages.</li>
                                        <li><strong>Reach remote audience:</strong> SMS can reach remote areas where internet connection is poor and email may not be accessible.</li>
                                        <li><strong>SMS short code:</strong> SMS shortcode can be used to subscribe or unsubscribe from SMS campaigns.</li>
                                        <li><strong>SMS and two-factor authentication:</strong> Our SMS can be used for two-factor authentication for added security.</li>
                                      </ul>
                                    </p> 

                                  <p>Overall, our bulk SMS is an effective and cost-efficient way to reach a large number of people quickly and efficiently, making it a valuable tool for businesses and organizations of all types and sizes.</p>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- End Privacy Policy Area --> */}

        <Footer />
    </>
  )
}

export default Why
