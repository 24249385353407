import Header from '../includes/dashboards/Header';
import PackageMenu from './nav/PackageMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';
import { Link } from 'react-router-dom';

import { useFetchAuth } from "../../hooks/auth";

const Payment = () => {

  useFetchAuth();

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <PackageMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                  <div className="content-wrapper">
                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="container pt-5">
                                  <h3 className="mb-3 mt-3 text-center">Payment Method(Bank Transfer/Deposit)</h3>
                                  <p className="w-75 mx-auto mt-2"><strong>Bank Name</strong> — Standard Bank</p>
                                  <p className="w-75 mx-auto mt-2"><strong>Branch Name</strong> — ONLINE BANKING</p>
                                  <p className="w-75 mx-auto mt-2"><strong>Branch Code</strong> — 7654</p>
                                  <p className="w-75 mx-auto mt-2"><strong>Account Holder</strong> — MR DAVID DN MTSWENI</p>
                                  <p className="w-75 mx-auto mt-2"><strong>Account Number</strong> — 10 19 926 834 5</p>
                                  <p className="w-75 mx-auto mt-2"><strong>Account type</strong> — SAVINGS</p>

                                  <p className="w-75 mx-auto mt-5"><strong>Referencing</strong> — Use your mobile number, name/company name and package name as a reference e.g. <strong>"27********* - Innetec - Gold "</strong></p>
                                  <p className="w-75 mx-auto mt-2 mb-5"><strong>Proof Of Payment</strong> — Then send proof of payment to <strong>sales@premiumsms.co.za</strong></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Payment
