import { Link } from 'react-router-dom';

const Theme = () => {
    return ( 
        <>
            {/* <div className="theme-setting-wrapper">
                <div id="settings-trigger"><i className="ti-settings"></i></div>
                <div id="theme-settings" className="settings-panel">
                <i className="settings-close ti-close"></i>
                <p className="settings-heading">SIDEBAR SKINS</p>
                <div className="sidebar-bg-options selected" id="sidebar-light-theme"><div className="img-ss rounded-circle bg-light border me-3"></div>Light</div>
                <div className="sidebar-bg-options" id="sidebar-dark-theme"><div className="img-ss rounded-circle bg-dark border me-3"></div>Dark</div>
                <p className="settings-heading mt-2">HEADER SKINS</p>
                <div className="color-tiles mx-0 px-4">
                    <div className="tiles success"></div>
                    <div className="tiles warning"></div>
                    <div className="tiles danger"></div>
                    <div className="tiles info"></div>
                    <div className="tiles dark"></div>
                    <div className="tiles default"></div>
                </div>
                <p className="settings-heading mt-2">THEMES</p>
                <div className="color-themes mx-0 px-4">
                    <Link to="#" className="color-theme default"></Link>
                    <Link to="#" className="color-theme dark"></Link>
                    <Link to="#" className="color-theme brown"></Link>
                </div>
                </div>
            </div> */}
        </>
     );
}
 
export default Theme;