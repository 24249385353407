import './../../assets/css/bootstrap.min.css';
import './../../assets/css/animate.min.css';
import './../../assets/css/pe-icon-7-stroke.css';
import './../../assets/css/fontawesome.min.css';
import './../../assets/css/meanmenu.css';
import './../../assets/css/owl.carousel.min.css';
import './../../assets/css/style.css';
import './../../assets/css/responsive.css';

const Header = () => {
    return ( 
        <>

        </> 
    );
}
 
export default Header;