import Header from '../../includes/dashboards/Header';
import MessageMenu from './../nav/MessageMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { getRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import Table from "./../../../components/Table";
import {useAuthHeader} from 'react-auth-kit'

const SentMessages = () => {
  const authHeader = useAuthHeader()

  useFetchAuth();

  const [messages,setMessages] = useState([])
  const [contacts,setContacts] = useState([])

  const [message] = useState("")
  const [successful] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  useEffect(()=>{
    async function fetchData() {
      const messages = await getRequest("messages/", authHeader())
      const contacts = await getRequest("contacts/", authHeader())
      setMessages(messages)
      setContacts(contacts)
    }
    fetchData()
  },[messages])

  const messagesData = (messages) => {
    const data = [];
  
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      const contact = contacts.find(c => c.id === message.contact_id);
  
      if (!message.status) {
        // skip iteration if message status is falsy
        continue;
      }
  
      data.push({
        id: i + 1,
        body: message.body,
        mobile: contact ? contact.mobile_number : "",
        date: message.created_date.split('T')[0],
        status: (
          <>
            {message.status ? (
              <button className="btn btn-outline-primary m-1">Sent</button>
            ) : (<button className="btn btn-outline-danger m-1">Failed</button>)}
          </>
        ),
      });
    }
  
    return data;
  };
  
  
  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <MessageMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                  {message && (
                      <div className="form-group">
                        <div className={ successful ? "alert alert-success" : "alert alert-danger"} role="alert" > {message} </div>
                      </div>
                    )}
                    <Table
                      data={messagesData(messages)}
                      columns={["ID", "Body", "Mobile", "Date", "Status"]}
                      title="Sent Messages"
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default SentMessages;
