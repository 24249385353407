import Header from '../../includes/dashboards/Header';
import UserMenu from '../nav/UserMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { getRequest, deleteRequest } from '../../../requests/requests';
import Table from "./../../../components/Table";
import { useFetchAuth, useCheckClient } from "../../../hooks/auth";
import { useAuthHeader } from 'react-auth-kit'

const Users = () => {

  const authHeader = useAuthHeader()

  useFetchAuth();
  useCheckClient();
  const [users,setUsers] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  useEffect(()=>{
    async function fetchData() {
      const users = await getRequest("users/", authHeader())
      setUsers(users)
    }
    fetchData()
  },[users])

  const onDeleteUser = async (id) => {

    return await deleteRequest(`users/${id}`, authHeader())

  }

  const usersData = (users, onDeleteUser) => {
    return users.map((user, i) => ({
      id: i + 1,
      name: user.first_name,
      surname: user.last_name,
      mobile: user.mobile_number,
      email: user.email,
      actions: (
        <>
          <Link to={`/admin/edit-user/${user.id}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-eye" aria-hidden="true"></i> View</Link>
          <Link to={`/admin/change-user-pass/${user.id}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-lock" aria-hidden="true"></i> Password</Link>
          <Link to={`/admin/add-package/${user.id}`} className="btn btn-outline-success border border-success m-1"><i className="fa fa-box" aria-hidden="true"></i> + Package</Link>
          <button onClick={() => onDeleteUser(user.id)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <UserMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <Table
                      data={usersData(users, onDeleteUser)}
                      columns={["ID", "Name", "Surname", "Mobile", "Email", "Actions"]}
                      title="Users"
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                      
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Users
