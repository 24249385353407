import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';

function scrollToTop() {
  window.scrollTo(0, 0);
}


const Terms = ({ login_className }) => {
  return (
    <>  
        <Header />
        <Menu login_className = { login_className } />

        {/* <!-- Start Page Title Area --> */}
        <div className="page-title-area item-bg3">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Terms & Conditions</h2>
                  <ul>
                    <li><Link to="/home" onClick={scrollToTop}>Home</Link>
                    </li>
                    <li>Terms & Conditions</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Page Title Area --> */}
        
        {/* <!-- Start Terms & Conditions Area --> */}
        <section className="about-area section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content">
                                <div className="about-content-text">
                                  <h2><span className="color-text">Terma & Conditions</span> on our bulk SMS</h2>

                                   <p><strong>Acceptable Use Policy: </strong>This outlines what types of content and messages are allowed to be sent using the platform, and what types of messages are prohibited.</p> 

                                   <p><strong>Introduction</strong>
                                   <br></br>These terms and conditions govern your use of our bulk SMS platform and services. By using our Services, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you may not use our Services.</p> 
                                  

                                   <p><strong>Description of Services</strong>
                                   <br></br>Our Services allow you to send mass text messages to your customers and clients. Our platform is designed to be user-friendly and easy to navigate, allowing businesses and organizations of all sizes to send bulk text messages quickly and efficiently.</p> 

                                   <p><strong>Registration and Account</strong>
                                   <br></br>To use our Services, you must create an account with us. You agree to provide accurate and complete information when creating your account, and to update your account information as necessary to keep it accurate and complete. You are responsible for maintaining the confidentiality of your account information, including your password.</p> 

                                   <p><strong>Use of Services</strong>
                                   <br></br>Our Services may only be used for lawful purposes. You may not use our Services to send spam, phishing messages, or any other unsolicited or illegal messages. You are responsible for ensuring that the content of your messages complies with all applicable laws and regulations.</p> 

                                   <p><strong>Privacy Policy</strong>
                                   <br></br>We take the privacy of our users seriously. Our privacy policy, which is available on our website, explains how we collect, use, and protect your personal information. By using our Services, you consent to our collection, use, and protection of your personal information in accordance with our privacy policy.</p> 

                                   <p><strong>Intellectual Property</strong>
                                   <br></br>All content and materials on our website and provided through our Services, including but not limited to text, graphics, logos, images, and software, are the property of <Link to={'/'} target={'_blank'}><strong>Premium<span className="color-text" style={{ fontSize: '15px' }}>SMS</span></strong></Link> or our licensors and are protected by copyright and trademark laws. You may not use any such content or materials without our prior written consent.</p> 

                                   <p><strong>Third-Party Links</strong>
                                   <br></br>Our website and Services may contain links to third-party websites. We do not control and are not responsible for the content or practices of any such websites. By including links to third-party websites, we do not imply that we endorse or approve of their content or practices.</p> 

                                   <p><strong>Governing Law</strong>
                                   <br></br>These terms and conditions shall be governed by and construed in accordance with the laws of <strong>South Africa</strong>, without giving effect to any principles of conflicts of law.</p> 

                                   <p><strong>Dispute Resolution</strong>
                                   <br></br>Any dispute arising out of or related to these terms and conditions or your use of our Services shall be resolved through binding arbitration in accordance with the commercial arbitration rules of <strong>South Africa</strong>.</p> 

                                   <p><strong>Termination</strong>
                                   <br></br>We may terminate your use of our Services at any time, with or without notice, for any reason or no reason.</p> 

                                   <p><strong>Disclaimer of Warranties</strong>
                                   <br></br>Our Services are provided "as is" and "as available" without warranty of any kind, either express or implied. We do not warrant that our Services will be error-free or uninterrupted.</p> 

                                   <p><strong>Limitation of Liability</strong>
                                   <br></br>In no event shall we be liable for any damages of any kind, including but not limited to direct, indirect, incidental, punitive, and consequential damages, arising out of or in connection with your use of our Services.</p> 

                                   <p><strong>Changes to Terms and Conditions</strong>
                                   <br></br>We reserve the right to change these terms and conditions at any time, with or without notice. Your continued use of our Services after any changes to these terms and conditions will constitute your acceptance of the new terms and conditions.
</p> 

                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- End Terms & Conditions Area --> */}

        <Footer />
    </>
  )
}

export default Terms
