import { Link } from 'react-router-dom';
// import ScriptTag from 'react-script-tag';

function scrollToTop() {
    window.scrollTo(0, 0);
}

const Footer = () => {
    return ( 
        <>
            <section className="footer-area section-padding">
                <div className="container">
                    {/* <div className="row subscribe-area">
                        <div className="col-lg-6 col-md-6">
                            <div className="section-title text-left mb-0">
                                <h3>Be the firt to get updates from us!</h3>
                                <p>Enter your email and subscribe</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <form className="newsletter-form">
                                <input type="email" className="input-newsletter" placeholder="Enter your email" name="EMAIL" required autoComplete="off" />
                                <button className="default-btn" type="submit">Subscribe <span></span></button>
                            </form>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="single-footer-widget">
                                <div className="footer-heading">
                                    <h3>About Us</h3>
                                </div>
                                <p>
                                <p>Welcome to <strong>Premium<span className="color-text" style={{ fontSize: '15px' }}>SMS</span></strong>, a leading provider of bulk SMS messaging solutions. We are a stand-alone portal powered
                                        by <a href={'https://innetechosting.com/'} target={'_blank'}>Innetec hosting</a>, ensuring the highest level of reliability and uptime for our clients. Our platform is designed 
                                        to be user-friendly and easy to navigate, allowing businesses and organizations of all sizes to send mass text messages 
                                        to their customers and clients quickly and efficiently. <Link to={'/about-us'} onClick={scrollToTop}>Read more</Link></p>
                                </p>
                                <div className="footer-info-contact">
                                    <span><strong>Phone:</strong> +27 71 429 5519</span>
                                </div>
                                <div className="footer-info-contact">
                                    <span><strong>Email:</strong> sales@premiumsms.co.za</span>
                                </div>
                                <div className="footer-info-contact">
                                    <span><strong>Address:</strong> 191 Rahima Street</span>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="single-footer-widget">
                                <div className="footer-heading">
                                    <h3>Quick links</h3>
                                </div>
                                <ul className="footer-quick-links">
                                    <li> <Link to="/" onClick={scrollToTop}>Home</Link></li>
                                    <li> <Link to="/about-us" onClick={scrollToTop}>About Us</Link></li>
                                    <li> <Link to="/use-cases" onClick={scrollToTop}>Uses Cases</Link></li>
                                    <li> <Link to="/our-packages" onClick={scrollToTop}>Packages</Link></li>
                                    <li> <Link to="/contact-us" onClick={scrollToTop}>Contact Us</Link></li>
                                    <li> <Link to="/sign-in" onClick={scrollToTop}>Login</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="copyright-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p> <i className="far fa-copyright"></i> {(new Date().getFullYear())} Premium <span className="color-text" style={{ fontSize: 15 }}> SMS</span> - All Rights Reserved.</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ul className="footer-social">
                                <li>
                                    <a href={'https://www.facebook.com/premiumsmsza/'} target={'_blank'} > <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <Link to="#"> <i className="fab fa-twitter"></i>
                                    </Link>
                                </li> */}
                                <li>
                                    <a href={'https://www.linkedin.com/company/premiumsms/'} target={'_blank'} > <i className="fab fa-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="go-top">
                <i className="fas fa-chevron-up"></i>
                <i className="fas fa-chevron-up"></i>
            </div>

        </>
     );
}
 
export default Footer;