import Header from '../../includes/dashboards/Header';
import MessageMenu from './../nav/MessageMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import Table from "./../../../components/Table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from '../../../requests/requests';

import { useFetchAuth, useCheckClient } from "../../../hooks/auth";
import { useAuthHeader } from 'react-auth-kit'

const Messages = () => {
  const authHeader = useAuthHeader()

  useFetchAuth();
  useCheckClient();

  const [messages,setMessages] = useState([])
  const [contacts,setContacts] = useState([])

  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  useEffect(()=>{
    async function fetchData() {
      const messages = await getRequest("messages/", authHeader())
      const contacts = await getRequest("contacts/", authHeader())
      setMessages(messages)
      setContacts(contacts)
    }
    fetchData()
  })
  
  const messagesData = (messages) => {
    return messages.map((message, i) => {
      const contact = contacts.find(c => c.id === message.contact_id);
      return {
        id: i + 1,
        body: message.body,
        mobile: contact ? contact.mobile_number : "",
        status: message.status,
      };
    });
  };
  

  // Resend SMS
  const Resend = async (message_id, contact_id, short_message, message_type) => {

    const message = { message_id, contact_id, short_message, message_type }

    const resendMessage = await postRequest(`messages/resend`, message, authHeader())
  
    if(resendMessage.status === 200){

      toast.success(resendMessage.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });

    }else{

      toast.error(resendMessage.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      
  }

  }

  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <MessageMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <Table
                      data={messagesData(messages, Resend)}
                      columns={["ID", "Body", "Mobile", "Status"]}
                      title="All Messages"
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Messages;
