import Header from '../../includes/dashboards/Header';
import ScheduleMenu from '../nav/ScheduleMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthHeader} from 'react-auth-kit'

const myContactz = JSON.parse(localStorage.getItem('myContactz'));
const myCSVContactz = JSON.parse(localStorage.getItem('contact_csv'));
const shortMessag = JSON.parse(localStorage.getItem('shortMessag'));


const AddSchedule = () => {
  const authHeader = useAuthHeader()

  // alert(myContactz[0])

  useFetchAuth();

  const [contacts,setContacts] = useState([])
  const [schedule_time, setTime] = useState('')
  const [contact_csv, setContactsCSV] = useState([]);
  const [my_contacts, setMyContacts] = useState([])
  const [message_type, setMessageType] = useState('SMS')
  const [schedule_date, setDate] = useState('')
  const [short_message, setShortMessage] = useState("")
  const [frequency, setFrequency] = useState("Once")  
  const [loading, setLoading] = useState(false)
  const [charactersLeft, setCharactersLeft] = useState(149);

  useEffect(()=>{
    async function fetchData() {
      const contacts = await getRequest("contacts/", authHeader())
      setContacts(contacts)
      if(myContactz){setContactsCSV(myCSVContactz)}
      setMyContacts(myContactz)
      setShortMessage(shortMessag)
    }
    fetchData()
  },[])

  //handle event when ever the option is selected.
  const handleChange1 = (event) => {
    setMyContacts(Array.from(event.target.selectedOptions, (option) => option.value));
  };
    
  //handle event when ever the option is selected.
  const handleChange2 = (event) => {
    setTime(Array.from(event.target.selectedOptions, (option) => option.value));
  };

  const clearSession = () =>{
    const itemsToRemove = ['templateBody', 'groupId', 'myContactz', 'contact_csv', 'shortMessag']
    // const itemsToRemove = ["templateBody"];
    itemsToRemove.forEach((item) => localStorage.removeItem(item));
  }

  const onSubmit = async(e) => {
    e.preventDefault()

    if (my_contacts.length < 1) {
      toast.error('Please select contacts', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }
    if (!short_message) {
      toast.error('Please add a message', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (short_message.length > 149) {
      toast.error('Message must not be more than 149 characters', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return;
    }

    if (!message_type) {
      toast.error('Please add a message type', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!schedule_time) {
      toast.error('Please select time', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!schedule_date) {
      toast.error('Please select date', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    const data = { my_contacts, contact_csv, short_message, message_type, schedule_time, schedule_date, frequency }

    const addSchedule = await postRequest(`messages/`, data, authHeader())

    if(addSchedule.status === 200){

      clearSession()

      setDate('')
      setShortMessage('')

      toast.success(addSchedule.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);

    }else{

      toast.error(addSchedule.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
  }

  

  const handleBodyChange = (e) => {
    const value = e.target.value;

    if (value.length <= 149) {
      setShortMessage(value);
      setCharactersLeft(149 - value.length);
    } else {
      setShortMessage(value.slice(0, 149));
    }
  }; 

  // alert(myContactz.length === 2)

  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <ScheduleMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Add Schedule</h4>
                              <p className="card-description">
                                Please make sure that you write a short message
                                (149 characters long)<br></br>
                                <strong>Dynamic fields, use [name] for name, [surname] for surname, [organization] for organization, [mobile] for  mobile, [email] for email</strong> <br></br>
                                <strong>CSV Dynamic fields, use [name] for name, [surname] for surname, [organization] for organization, [mobile] for  mobile, [email] for email, [activity] for activity, [time] for time, [amount] for amount</strong>
                              </p>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                <div className="form-group">
                                  <label for="inputContacts1">Contacts</label>
                                  <select 
                                    className="form-control form-control-lg"
                                    id="inputContacts1"
                                    multiple
                                    onChange={handleChange1}
                                    size="10"
                                  >
                                    {contacts?.map((contact, index) => {
                                      const isSelected = my_contacts?.includes(contact.id);
                                      return (
                                        <option key={index} value={contact.mobile_number} selected={isSelected}>
                                          {contact.first_name} ({contact.mobile_number})
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputMessage1">Message (149 Characters)</label>
                                  <p className={`${charactersLeft > 30 ? 'text-green' : 'text-red'}`}>{charactersLeft} Characters Left</p>
                                  <textarea type="text" className="form-control" id="inputMessage1" value={short_message} onChange={handleBodyChange} cols="10" rows="15"
                                   maxLength={149}>{ short_message }</textarea>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputScheduleTime1">Schedule Time</label>
                                  <select className="form-control form-control-lg" id="inputScheduleTime1" multiple onChange={handleChange2}>
                                    <option value="9AM">9:00 AM</option>
                                    <option value="12PM">12:00 PM</option>
                                    <option value="3PM">3:00 PM</option>
                                  </select>
                                </div>

                                {/* <div className="form-group">
                                  <label htmlFor="inputContacts1">Message Type</label>

                                  <select className="form-control form-control-lg" id="inputMessageType1" onChange={(e) => setMessageType(e.target.value)}>
                                    <option value="">Select Message Type</option>
                                    <option value="SMS">SMS</option>
                                    <option value="WhatsApp">WhatsApp</option>
                                    <option value="Email">Email</option>
                                  </select>
                                </div> */}
                                <div className="form-group">
                                  <label htmlFor="inputScheduleDate1">Schedule Date</label>
                                  <input type="date" className="form-control" id="inputScheduleDate1" value={schedule_date} onChange={(e) => setDate(e.target.value)} />
                                </div>

                                {/* <div className="form-group">
                                  <label htmlFor="inputFrequency1">Frequency</label>
                                  <select className="form-control form-control-lg" id="inputFrequency1" onChange={(e) => setFrequency(e.target.value)}>
                                    <option value="">Select Frequency</option>
                                    <option value="Once">Once</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                  </select>
                                </div> */}
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Add Schedule</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default AddSchedule
