import Header from '../../includes/dashboards/Header';
import GroupMenu from '../nav/GroupMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { getRequest, deleteRequest } from '../../../requests/requests';
import { useNavigate } from 'react-router-dom';
import { useFetchAuth } from "../../../hooks/auth";
import Table from "./../../../components/Table";
import {useAuthHeader} from 'react-auth-kit'

const Groups = () => {
  const authHeader = useAuthHeader()
  let navigate = useNavigate();

  useFetchAuth();

  const [groups,setGroups] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  useEffect(()=>{
    async function fetchData() {
      const groups = await getRequest("groups/", authHeader())
      setGroups(groups)
    }
    fetchData()
  })

  const onDeleteGroup = async (id) => {

    return await deleteRequest(`groups/${id}`, authHeader())

  }

  const sendMessage = (id) =>{
 
    localStorage.setItem("groupId", JSON.stringify(id));

    navigate("/send-sms");
    window.location.reload();

  }

  const groupsData = (groups, sendMessage, onDeleteGroup) => {
    return groups.map((group, i) => ({
      id: i + 1,
      name: group.name,
      actions: (
        <>
          <Link to={`/edit-group/${group.id}`} className="btn btn-outline-primary border border-primary m-1"><i className="fa fa-eye" aria-hidden="true"></i> More</Link>
          <button onClick={() => sendMessage(group.id)} className="btn btn-outline-success border border-success m-1"><i className="fa fa-paper-plane" aria-hidden="true"></i> Send Message</button>
          <button onClick={() => onDeleteGroup(group.id)} className="btn btn-outline-danger border border-danger m-1"><i className="fa fa-trash"  aria-hidden="true"></i> Delete</button>
        </>
      ),
    }));
  };

  return (
    <div>  

        <body className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <GroupMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <Table
                        data={groupsData(groups, sendMessage, onDeleteGroup)}
                        columns={["ID", "Name", "Actions"]}
                        title="Groups"
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </body>
    </div>
  )
}

export default Groups;
