import Header from '../includes/dashboards/Header';
import PackageMenu from './nav/PackageMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';
import { Link } from 'react-router-dom';

import { useFetchBuyPackage } from '../../hooks/client/client';
import { useFetchAuth } from "../../hooks/auth";

const BuyPackage = () => {

  useFetchAuth();
  const { packages } = useFetchBuyPackage();

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <PackageMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                      <div className="content-wrapper">
                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="container text-center pt-5">
                                  <h3 className="mb-3 mt-5">Advance your bussiness communication today</h3>
                                  <p className="w-75 mx-auto mb-5">Choose a plan that suits you the best.</p>
                                  <div className="row pricing-table">

                                  {packages.map((our_package, index) => (

                                    <div key={index} className="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                                      <div className="card border-primary border pricing-card-body">
                                        <div className="text-center pricing-card-head">

                                          <h3 className="
                                          { our_package.name == 'Bronce' ? text-gray : ''} 
                                          { our_package.name == 'Gold' ? text-success : ''} 
                                          { our_package.name == 'Platinum' ? text-primary : ''">
                                          
                                          { our_package.name }
                                          
                                          </h3>

                                          <p>{ our_package.desc }</p>
                                          <h1 className="fw-normal mb-4">R{ our_package.price }</h1>
                                        </div>
                                        <ul className="list-unstyled plan-features">
                                          <li>{ our_package.sms } SMSs</li>
                                          <li>{ our_package.support } Support</li>
                                          <li>{ our_package.sender_ID ? "Custom Sender ID" : "No Custom Sender ID" }</li>
                                          <li>{ our_package.requests } Send Requests</li>
                                          <li>{ our_package.sms_report ? "SMS Reports" : "No SMS Reports" }</li>
                                          <li>{ our_package.subscription_report ? "Subcription Report" : "No Subcription Report" }</li>
                                        </ul>
                                        {/* <div className="wrapper">
                                          <Link to={`/subscribe/${our_package.id}`} className="{ our_package.name === 'Gold' ? btn btn-success btn-block : btn btn-outline-primary btn-block}">Subscribe</Link>
                                        </div> */}

                                        <div className="wrapper">
                                          <Link to={`/payment-options`} className="{ our_package.name === 'Gold' ? btn btn-success btn-block : btn btn-outline-primary btn-block}">Subscribe</Link>
                                        </div>

                                        {/* <p className="mt-3 mb-0 plan-cost { our_package.name == 'Bronce' ? text-gray : ''} 
                                        { our_package.name == 'Gold' ? text-success : ''} 
                                        { our_package.name == 'Bronce' ? text-primary : ''}">{ our_package.name }</p> */}
                                      </div>
                                    </div>


                                    ))}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default BuyPackage
