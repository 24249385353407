import React, { useEffect, useState  } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import UseCases from './pages/UseCases';
import Packages from './pages/Packages';
import FAQs from './pages/FAQs';
import Why from './pages/Why';
import Policies from './pages/Policies';
import Terms from './pages/Terms';
import Contact from './pages/Contact';


import Signin from './pages/Signin';
import SignOut from './pages/SignOut';
import Signup from './pages/Signup';


import Dashboard from './pages/client/Dashboard';
import SendSMS from './pages/client/SendSMS';
import BuyPackage from './pages/client/BuyPackage';
import Payment from './pages/client/Payment';
import Subscribe from './pages/client/Subscribe';
import SenderID from './pages/client/SenderID';
import Contacts from './pages/client/contact/Contacts';
import AddContact from './pages/client/contact/AddContact';
import EditContact from './pages/client/contact/EditContact';
import Schedules from './pages/client/schedule/Schedules';
import AddSchedule from './pages/client/schedule/AddSchedule';
import EditSchedule from './pages/client/schedule/EditSchedule';
import Templates from './pages/client/template/Templates';
import AddTemplate from './pages/client/template/AddTemplate';
import EditTemplate from './pages/client/template/EditTemplate';
import Groups from './pages/client/group/Groups';
import AddGroup from './pages/client/group/AddGroup';
import EditGroup from './pages/client/group/EditGroup';
import Billing from './pages/client/billing/Billing';
import Messages from './pages/client/message/Messages';
import SentMessages from './pages/client/message/SentMessages';
import FailedMessages from './pages/client/message/FailedMessages';
import Profile from './pages/client/profile/EditUser';
import ChangePassword from './pages/client/profile/ChangePass';


import AdminDashboard from './pages/admin/Dashboard';
import SenderIDRequests from './pages/admin/SenderIDRequests';
import Users from './pages/admin/user/Users';
import AddUser from './pages/admin/user/AddUser';
import AddSMS from './pages/admin/user/AddSMS';
import EditUser from './pages/admin/user/EditUser';
import ChangePass from './pages/admin/user/ChangePass';
import SendScheduled from './pages/admin/schedule/Send';
import AdminMessages from './pages/admin/message/Messages';
import AdminSentMessages from './pages/admin/message/SentMessages';
import AdminFailedMessages from './pages/admin/message/FailedMessages';
import EmailMarket from './pages/admin/EmailMarket';
import { RequireAuth } from 'react-auth-kit';

import $ from 'jquery';
import SMSvsEmail from "./pages/SMSvsEmail";

function App() {
  
  useEffect(() => {
    $('[data-bs-toggle="offcanvas"]').on("click", function() {
      $('.sidebar-offcanvas').toggleClass('active')
    });
    
  }, []);


  return (
    <Router>
      <Routes>

          {/* //Website routes */}
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/use-cases' element={<UseCases />} />
          <Route path='/our-packages' element={<Packages />} />
          <Route path='/faqs' element={<FAQs />} />
          <Route path='/why-sms' element={<Why />} />
          <Route path='/smsvsemail' element={<SMSvsEmail />} />
          <Route path='/privacy-policies' element={<Policies />} />
          <Route path='/terms-conditions' element={<Terms />} />
          <Route path='/contact-us' element={<Contact />} />



          {/* //Client Routes */}
          <Route path='/sign-in' element={ (<Signin />) } />
          <Route path='/sign-up' element={ (<Signup />) } />
          <Route path='/sign-out' element={ (<SignOut />) } />

            <Route path='/dashboard' element={
            <RequireAuth loginPath='/sign-in'>
              <Dashboard />
            </RequireAuth>}/>

          
            <Route path='/send-sms' element={
            <RequireAuth loginPath='/sign-in'>
              <SendSMS />
            </RequireAuth>}/>

            <Route path='/buy-package' element={
            <RequireAuth loginPath='/sign-in'>
              <BuyPackage />
            </RequireAuth>}/>

            <Route path='/payment-options' element={
            <RequireAuth loginPath='/sign-in'>
              <Payment />
            </RequireAuth>}/>

            <Route path='/subscribe/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <Subscribe />
            </RequireAuth>}/>
            
            <Route path='/sender-id' element={
            <RequireAuth loginPath='/sign-in'>
              <SenderID />
            </RequireAuth>}/>

            <Route path='/contacts' element={
            <RequireAuth loginPath='/sign-in'>
              <Contacts />
            </RequireAuth>}/>

            <Route path='/add-contact' element={
            <RequireAuth loginPath='/sign-in'>
              <AddContact />
            </RequireAuth>}/>

            <Route path='/edit-contact/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <EditContact />
            </RequireAuth>}/>

            <Route path='/messages' element={
            <RequireAuth loginPath='/sign-in'>
              <Messages />
            </RequireAuth>}/>

            <Route path='/sent-messages' element={
            <RequireAuth loginPath='/sign-in'>
              <SentMessages />
            </RequireAuth>}/>

            <Route path='/failed-messages' element={
            <RequireAuth loginPath='/sign-in'>
              <FailedMessages />
            </RequireAuth>}/>

            <Route path='/schedules' element={
            <RequireAuth loginPath='/sign-in'>
              <Schedules />
            </RequireAuth>}/>

            <Route path='/add-schedule' element={
            <RequireAuth loginPath='/sign-in'>
              <AddSchedule />
            </RequireAuth>}/>

            <Route path='/edit-schedule/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <EditSchedule />
            </RequireAuth>}/>

            <Route path='/templates' element={
            <RequireAuth loginPath='/sign-in'>
              <Templates />
            </RequireAuth>}/>

            <Route path='/add-template' element={
            <RequireAuth loginPath='/sign-in'>
              <AddTemplate />
            </RequireAuth>}/>

            <Route path='/edit-template/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <EditTemplate />
            </RequireAuth>}/>

            <Route path='/groups' element={
            <RequireAuth loginPath='/sign-in'>
              <Groups />
            </RequireAuth>}/>

            <Route path='/add-group' element={
            <RequireAuth loginPath='/sign-in'>
              <AddGroup />
            </RequireAuth>}/>

            <Route path='/edit-group/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <EditGroup />
            </RequireAuth>}/>

            <Route path='/billing' element={
            <RequireAuth loginPath='/sign-in'>
              <Billing />
            </RequireAuth>}/>

            <Route path='/profile' element={
            <RequireAuth loginPath='/sign-in'>
              <Profile />
            </RequireAuth>}/>

            <Route path='/change-password' element={
            <RequireAuth loginPath='/sign-in'>
              <ChangePassword />
            </RequireAuth>}/>

            {/* super user routes */}

            <Route path='/admin/dashboard' element={
            <RequireAuth loginPath='/sign-in'>
              <AdminDashboard />
            </RequireAuth>}/>

            <Route path='/admin/users' element={
            <RequireAuth loginPath='/sign-in'>
              <Users />
            </RequireAuth>}/>

            <Route path='/admin/add-user' element={
            <RequireAuth loginPath='/sign-in'>
              <AddUser />
            </RequireAuth>}/>

            <Route path='/admin/edit-user/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <EditUser />
            </RequireAuth>}/>

            <Route path='/admin/add-package/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <AddSMS />
            </RequireAuth>}/>

            <Route path='/admin/sender-id/requests' element={
            <RequireAuth loginPath='/sign-in'>
              <SenderIDRequests />
            </RequireAuth>}/>

            <Route path='/admin/change-user-pass/:id' element={
            <RequireAuth loginPath='/sign-in'>
              <ChangePass />
            </RequireAuth>}/>

            <Route path='/admin/send-scheduled' element={
            <RequireAuth loginPath='/sign-in'>
              <SendScheduled />
            </RequireAuth>}/>

            <Route path='/admin/email-marketing' element={
            <RequireAuth loginPath='/sign-in'>
              <EmailMarket />
            </RequireAuth>}/>

            <Route path='/admin/messages' element={
            <RequireAuth loginPath='/sign-in'>
              <AdminMessages />
            </RequireAuth>}/>

            <Route path='/admin/sent-messages' element={
            <RequireAuth loginPath='/sign-in'>
              <AdminSentMessages />
            </RequireAuth>}/>

            <Route path='/admin/failed-messages' element={
            <RequireAuth loginPath='/sign-in'>
              <AdminFailedMessages />
            </RequireAuth>}/>

        </Routes>
    </Router>
  );
}

export default App;
