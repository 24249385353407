import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';
function scrollToTop() {
    window.scrollTo(0, 0);
  }
  

const FAQs = ({ login_className }) => {
    return (
      <>  
        <Header />
        <Menu login_className = { login_className } />

        {/* <!-- Start Page Title Area --> */}
        <div className="page-title-area item-bg2">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Frequently Ask Question</h2>
                            <ul>
                                <li><Link to="/home" onClick={scrollToTop}>Home</Link>
                                </li>
                                <li>Faq</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Page Title Area --> */}
        
        {/* <!-- Start Faq Section --> */}
        <section className="about-area section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content">
                                <div className="about-content-text">
                                  <h2><span className="color-text">FAQs</span> on bulk SMS?</h2>

                                   <p><strong>Q: How many characters can I include in an SMS message?</strong>
                                   <br></br>
                                   A: The standard SMS message limit is 160 characters. However, we 
                                    offer support for longer messages, which are then split into multiple segments 
                                    and delivered to the recipient as one message but charged as two messages.</p> 

                                    <p><strong>Q: How do I ensure that my bulk SMS messages are delivered to the intended recipients?</strong>
                                    <br></br>
                                    A: To ensure that your bulk SMS messages are delivered to the intended recipients, 
                                    it is important to ensure that the phone numbers you are sending messages to are 
                                    valid and that recipients have opted-in/ not blocked to receive SMS messages from your business.</p> 

                                    <p><strong>Q: How can I track the success of my bulk SMS campaigns?</strong>
                                    <br></br>
                                    A: We offer tracking and reporting features that allow you to track metrics such as delivery rate, open rate, 
                                    and click-through rate. These metrics can help you understand how your campaigns are performing and make adjustments 
                                    as needed.</p> 

                                    <p><strong>Q: Can I schedule my bulk SMS messages to be sent at a specific time?</strong>
                                    <br></br>
                                    A: We offer scheduling features that allow you to schedule your messages to be sent at a specific time, 
                                    which can be useful for sending time-sensitive information or for planning marketing campaigns.</p> 

                                    <p><strong>Q: What is the best time to send bulk SMS messages?</strong>
                                    <br></br>
                                    A: There is no definitive answer to this question as it depends on the purpose of your campaign and your target audience. 
                                    However, generally, it is best to avoid sending messages during non-working hours, such as late at night or early in the morning, 
                                    to ensure that the message is seen and read by the recipient.</p> 

                                    <p><strong>Q: Can I personalize my bulk SMS messages?</strong>
                                    <br></br>
                                    A: We offer personalization features that allow you to include recipient-specific information, such as names or locations, in your messages. 
                                    Personalization can help increase engagement and response rates.</p> 

                                    <p><strong>Q: Can I send SMS messages to international numbers?</strong>
                                    <br></br>
                                    A: Yes, we offer the ability to send messages to international numbers. However, it's important to note that there may be additional costs and legal 
                                    requirements for sending SMS messages to international numbers. It's also important to check if the international numbers you want to send to are supported 
                                    by our bulk SMS service.</p> 

                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- End Faq Section --> */}
        <Footer />
    </>
  )
}

export default FAQs
