import Header from '../includes/dashboards/Header';
import EmailMarketMenu from './nav/EmailMarketMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { postRequest } from '../../requests/requests';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from "papaparse";
import { useFetchAuth, useCheckClient } from "../../hooks/auth";
import {useAuthHeader} from 'react-auth-kit'

const EmailMarket = () => {
  const authHeader = useAuthHeader()

  const [fileData, setFileData] = useState({});
  const [loading, setLoading] = useState(false)

  useFetchAuth();
  useCheckClient();

  const handleCSVFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const result = Papa.parse(event.target.result, {
        header: true, // assume the first row is the header row
      });
      setFileData(result.data);
    };
  };

  const handleBulkUpload = async (e) => {

    e.preventDefault()

    let emailMarket

    if(fileData.length){
      await Promise.all(fileData.map(async (file) => {
        if (file.Name !== "") {
          const data = {
            name: file.Name,
            email: file.Email
          };
         
            emailMarket = await postRequest(`users/email/market/`, data, authHeader());

        }
      }));
    }else{
      toast.error('Please select a csv file with data', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if(emailMarket.status === 200){

      toast.success("Email Marketing Sent!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);

    }else{

      toast.error(emailMarket.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });

    }

  }

  return (
    <div>  
        <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <EmailMarketMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                      <div className="content-wrapper">
                      <div className="card">
                          <div className="card-body">
                            <h4 className="card-title">Email Marketing</h4>
                            <p className="card-description">Select a csv file.</p>
                            <form className="forms-sample">

                                <div className="form-group">
                                  <label htmlFor="inputCSV1">Upload from csv</label>
                                  <input type="file" className="form-control" id="inputCSV1" accept=".csv" onChange={handleCSVFileChange} />
                                  {/* {fileData && <p>Selected file: {fileData.name}</p>} */}
                                </div> 

                                <button type="submit" className="btn btn-primary me-2" onClick={handleBulkUpload} disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Send Email Marketing</span>
                                  </button>
                              </form>
                          </div>
                        </div>
                      </div> 
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default EmailMarket;
