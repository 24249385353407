import './../vendors/feather/feather.css';
import './../vendors/mdi/css/materialdesignicons.min.css';
import './../vendors/ti-icons/css/themify-icons.css';
import './../vendors/typicons/typicons.css';
import './../vendors/simple-line-icons/css/simple-line-icons.css';
import './../vendors/css/vendor.bundle.base.css';
import './../css/vertical-layout-light/style.css';


const Styles = () => {
    return ( 
        <>
        </> 
    );
}
 
export default Styles;