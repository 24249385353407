import Header from "../../includes/dashboards/Header";
import BillingMenu from "../nav/BillingMenu";
import Footer from "../../includes/dashboards/Footer";
import Theme from "../../includes/dashboards/Theme";
import Table from "./../../../components/Table";
import { useFetchBilling } from "../../../hooks/client/client";
import { useFetchAuth } from "../../../hooks/auth";
import { useState } from 'react';

const Billing = () => {
  useFetchAuth();
  const { packages, user_packages } = useFetchBilling();

  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const packageData = user_packages
    .map((user_package, index) => {
      const packageInfo = packages.find(
        (packag) => packag.id === user_package.package_id
      );
      if (packageInfo) {
        return {
          id: index + 1,
          name: packageInfo.name,
          price: packageInfo.price,
        };
      }
      return null;
    })
    .filter(Boolean);

  return (
    <div>
      <div className="with-welcome-text">
        <div className="container-scroller">
          <Header />
          {/* <!-- partial --> */}
          <div className="container-fluid page-body-wrapper">
            <Theme />

            <BillingMenu />

            {/* <!-- partial --> */}

            <div className="main-panel">
              <Table
                data={packageData}
                columns={["ID", "Name", "Price"]}
                title="User Packages"
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
              <Footer />
            </div>

            {/* <!-- main-panel ends --> */}
          </div>

          {/* <!-- page-body-wrapper ends --> */}
        </div>

        {/* <!-- container-scroller --> */}
      </div>
    </div>
  );
};

export default Billing;
