import logo from '../../assets/img/logo-black.png';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

const Menu = ({ login_className }) => {
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isHelpVisible, setIsHelpVisible] = useState(false);

    const handleMobileMenuToggle = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    const handleHelpToggle = () => {
        setIsHelpVisible(!isHelpVisible);
    };

    return ( 
        <div className="navbar-area">
            <div className="techzone-responsive-nav index-navber-responsive">
                <div className="container">
                    <div className="techzone-responsive-menu">
                        <div className='row'>

                            <div className='col-10'>
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logo} className="white-logo" alt="" />
                                        <img src={logo} className="black-logo" alt="" />
                                    </Link>
                                </div>

                            </div>

                            <div className='col-2'>

                            <button className="mobile-menu-toggle" onClick={handleMobileMenuToggle}>
                                <i className={`fa ${isMobileMenuVisible ? 'fa-times' : 'fa-bars'}`}></i>
                            </button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {isMobileMenuVisible && (
                <div className="mobile-menu">
                    <ul className="mobile-menu-items">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-us" className="nav-link">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/use-cases" className="nav-link">Uses Cases</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/our-packages" className="nav-link">Packages</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="#" className="nav-link" onClick={ handleHelpToggle }>Get Help <i className="fas fa-chevron-down"></i></Link>
                            {isHelpVisible && (
                            
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <Link to="/smsvsemail" className="nav-link">SMS vs Email</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/why-sms" className="nav-link">Why SMS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/faqs" className="nav-link">FAQ</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/terms-conditions" className="nav-link">Terms & Conditions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/privacy-policies" className="nav-link">Privacy Policy</Link>
                                </li>
                            </ul>
                            )}
                        </li>
                        <li className="nav-item">
                            <Link to="/contact-us" className="nav-link">Contact Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard" className={`nav-link ${login_className}`} target={'_blank'}>
                                <i className="fa fa-solid fa-user-lock"></i> Log In / Signup
                                <span></span>
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
            <div className="techzone-nav index-navber">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} className="white-logo" alt="" />
                            <img src={logo} className="black-logo" alt="" />
                        </Link>
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" >Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about-us" className="nav-link" >About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/use-cases" className="nav-link" >Uses Cases</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/our-packages" className="nav-link" >Packages</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" >Get Help <i className="fas fa-chevron-down"></i></Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/smsvsemail" className="nav-link">SMS vs Email</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/why-sms" className="nav-link" >Why SMS</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/faqs" className="nav-link" >FAQ</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/terms-conditions" className="nav-link" >Terms & Conditions</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/privacy-policies" className="nav-link" >Privacy Policy</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact-us" className="nav-link" >Contact Us</Link>
                                </li>
                            </ul>
                            <div className="other-option">
                                <Link className={login_className} to="/dashboard" target={'_blank'}>
                                    <i className="fa fa-solid fa-user-lock"></i> Log In / Signup
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Menu;
