import Header from '../../includes/dashboards/Header';
import ContactMenu from '../nav/ContactMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getRequest, patchRequest } from '../../../requests/requests';
import { useFetchAuth } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthHeader} from 'react-auth-kit'

const EditContact = () => {
  const authHeader = useAuthHeader()

  const {id} = useParams(); // getting url id
   
  useFetchAuth();

  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [mobile_number, setMobileNumber] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const fetchContact = async () => {
      try {
        const contact = await getRequest(`contacts/${id}`, authHeader());
        contact.map((contact) => {
          setFirstName(contact.first_name);
          setLastName(contact.last_name);
          setCompany(contact.company);
          setMobileNumber(contact.mobile_number);
          setEmail(contact.email);
        })
      } catch (error) {
        console.error(error);
        toast.error("Failed to retrieve contact information", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      }
    };
    fetchContact();
  }, [id]);

  const onSubmit = async(e) => {
    e.preventDefault()

    if (!first_name) {
      toast.error('Please add a first name', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    // if (!last_name) {
    //   toast.error('Please add a last name', {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 5000
    //   });
    //   return
    // }

    if (!mobile_number) {
      toast.error('Please add a mobile number', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!/^(?!\+)(\b27\d{9}\b)$/.test(mobile_number)) {
      toast.error('Please enter a valid mobile number starting with 27 and exactly 11 digits.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    const data = { first_name, last_name, company, mobile_number, email}

    const updateContact = await patchRequest(`contacts/${id}`, data, authHeader())

    if(updateContact.status === 200){

      setFirstName('')
      setLastName('')
      setMobileNumber('')
      setEmail('')

      toast.success(updateContact.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);

    }else{

      toast.error(updateContact.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }


  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <ContactMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Edit Contact</h4>
                              <p className="card-description">Mobile number is a required field.</p>
                              <form className="forms-sample" onSubmit={onSubmit}>

                                <div className="form-group">
                                  <label htmlFor="inputFistname1">Fistname *</label>
                                  <input type="text" className="form-control" id="inputFistname1" value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputLastname1">Lastname</label>
                                  <input type="text" className="form-control" id="inputLastname1" value={last_name} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputOrganization1">Organization</label>
                                  <input type="text" className="form-control" id="inputOrganization1" value={company} onChange={(e) => setCompany(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputMobileNumber1">Mobile Number *</label>
                                  <input type="text" className="form-control" id="inputMobileNumber1" value={mobile_number} onChange={(e) => setMobileNumber(e.target.value)} />
                                  {mobile_number.length > 0 && !/^(?!\+)(\b27\d{9}\b)$/.test(mobile_number) && <p className="alert alert-warning">Please enter a valid mobile number starting with 27 and exactly 11 digits.</p>}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputEmail1">Email</label>
                                  <input type="email" className="form-control" id="inputEmail1" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Update Contact</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default EditContact
