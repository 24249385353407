import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { postRequest } from '../requests/requests';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = ({ login_className }) => {

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')


    const onSubmit = async(e) => {
        e.preventDefault()

    if (!name) {
      toast.error('Please add your fulname', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (!email) {
        toast.error('Please add your email', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        return
      }

      if (!subject) {
        toast.error('Please add your subject', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        return
      }


      if (!message) {
        toast.error('Please add your message', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        return
      }


    const data = { name, email, subject, message }

    const contactForm = await postRequest(`home/contact/`, data)

    if(contactForm.status === 200){

        setName('')
        setEmail('')
        setSubject('')
        setMessage('')
    
        toast.success(contactForm.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });
    
    }else{

      toast.error(contactForm.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      
    }
    
  }



    return (
      <>  
      <ToastContainer />
        <Header />
        <Menu login_className = { login_className } />

            {/* Start Page Title Area */}
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Contact Us</h2>
                                <ul>
                                    <li><Link to="/home" onClick={scrollToTop}>Home</Link>
                                    </li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Title Area  */}
            {/* Start Contact Section  */}
            <div className="contact-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="contact-information-box-3">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                        <div className="single-contact-info-box">
                                            <div className="contact-info">
                                                <h6>Address:</h6>
                                                <p>191 Rahima Street</p>
                                            </div>
                                            <div className="contact-info-bg-icon">
                                                <i className="fa fa-map"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                        <div className="single-contact-info-box">
                                            <div className="contact-info">
                                                <h6>Phone:</h6>
                                                <p>+27 71 429 5519</p>
                                            </div>
                                            <div className="contact-info-bg-icon">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                        <div className="single-contact-info-box">
                                            <div className="contact-info">
                                                <h6>Email:</h6>
                                                <p>sales@premiumsms.com</p>
                                            </div>
                                            <div className="contact-info-bg-icon">
                                                <i className="fa fa-envelope"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="contact-form-box contact-form contact-form-3">
                                <div className="form-container-box">
                                    <form className="contact-form form" id="ajax-contact" onSubmit={onSubmit}>
                                        <div className="controls">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="text" className="form-control" id="name" onChange={(e) => setName(e.target.value)} placeholder="Name*" required="required" data-error="Name is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="email" className="form-control" id="email"  onChange={(e) => setEmail(e.target.value)} placeholder="Email*" required="required" data-error="Valid email is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group form-input-box">
                                                        <input type="text" className="form-control"  onChange={(e) => setSubject(e.target.value)} placeholder="Subject" required="required" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group form-input-box">
                                                        <textarea className="form-control" id="message"  onChange={(e) => setMessage(e.target.value)} rows="9" placeholder="Write Your Message*" required="required" data-error="Please, leave us a message."></textarea>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit" className="default-btn" data-text="Send Message">Send Message</button>
                                                </div>
                                                <div className="messages">
                                                    <div className="alert alert alert-success alert-dismissable alert-dismissable hidden" id="msgSubmit"><button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button> Thank You! your message has been sent. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* End Contact Section*/}
            
            {/* Map Section Start  */}
            {/* <div className="map-area">
                <div className="map-content">
                    <div className="map-canvas" id="contact-map"></div>
                </div>
            </div> */}
            {/* <!-- Map Section End */}
                
        <Footer />
    </>
  )
}

export default Contact
