import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../../requests/requests';
import AuthService from "../../services/auth.service";
import { useParams } from 'react-router-dom';
import { useSignOut, useAuthUser, useAuthHeader } from 'react-auth-kit'

export function useFetchSignOut() {
  const signOut = useSignOut()
  
  useEffect(() => {
    signOut()
    AuthService.logout().then(
      (response) => {
      },
      (error) => {
        const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
    );
  }, []);

}

export function useFetchDashboard() {
    const auth = useAuthUser()()
    const authHeader = useAuthHeader()

    // alert(authHeader())

    const [loading, setLoading] = useState(false);

    const [packages,setPackages] = useState([])
    const [contacts,setContacts] = useState([])
    const [templates,setTemplates] = useState([])
    const [groups,setGroups] = useState([])
    const [user_packages,setUserPackages] = useState([])
    const [messages,setMessages] = useState([])
    const [lastmessage,setLastMessages] = useState([])
    const [sentMessages,setSentMessages] = useState([])
    const [failedMessages,setFailedMessages] = useState([])
    const [scheduledMessages,setScheduledMessages] = useState([])
    const [no_of_sms, setSMS] = useState([])
    const [lastMessageSuccessful,setLastMessageSuccessful] = useState(0)
    const [lastMessageFailed,setLastMessageFailed] = useState(0)

  useEffect(()=>{
    async function fetchData() {
      const packages = await getRequest("packages/", authHeader())
      const contacts = await getRequest("contacts/", authHeader())
      const templates = await getRequest("templates/", authHeader())
      const groups = await getRequest("groups/", authHeader())
      const user_packages = await getRequest("user-package/", authHeader())
      const messages = await getRequest("messages/", authHeader())
      const lastmessage = await getRequest("messages/new/", authHeader())
      const sentMessages = await getRequest("messages/status/1", authHeader())
      const failedMessages = await getRequest("messages/status/0", authHeader())
      const scheduledMessages = await getRequest("schedules/", authHeader())
      const user = await getRequest(`users/${auth?.userID}`, authHeader())
      setPackages(packages)
      setContacts(contacts)
      setTemplates(templates)
      setGroups(groups)
      setUserPackages(user_packages)
      setMessages(messages)
      setLastMessages(lastmessage)
      setSentMessages(sentMessages)
      setFailedMessages(failedMessages)
      setScheduledMessages(scheduledMessages)

      let lastMessageSuccessful = 0;
      let lastMessageFailed = 0;

      lastmessage.map((message) => {
        
        if(message.status === 1){
          lastMessageSuccessful++;
        }else{
          lastMessageFailed++;
        }
      })

      setLastMessageSuccessful(lastMessageSuccessful)
      setLastMessageFailed(lastMessageFailed)

      user.map((user, index) => {
        setSMS( user.no_of_sms )
      })
    }
    fetchData()
    setLoading(false); // Stop loading
  },[messages])
  return { packages, contacts, templates, groups, user_packages, messages, lastmessage, lastMessageSuccessful, lastMessageFailed, sentMessages, failedMessages, scheduledMessages, no_of_sms, loading };
}

export function useFetchAdminDashboard() {
  const authHeader = useAuthHeader()

  const [packages,setPackages] = useState([])
  const [user_packages,setUserPackages] = useState([])
  const [sentMessages,setSentMessages] = useState([])
  const [failedMessages,setFailedMessages] = useState([])
  const [activeUsers,setActiveUsers] = useState([])
  const [inActiveUsers,setInActiveUsers] = useState([])
  const [loading, setLoading] = useState(false);

useEffect(()=>{
  async function fetchData() {
    const packages = await getRequest("packages/", authHeader())
    const user_packages = await getRequest("user-package/", authHeader())
    const sentMessages = await getRequest("messages/status/1", authHeader())
    const failedMessages = await getRequest("messages/status/0", authHeader())
    const activeUsers = await getRequest(`users/status/9356`, authHeader())
    const inActiveUsers = await getRequest(`users/status/0`, authHeader())
    setPackages(packages)
    setUserPackages(user_packages)
    setSentMessages(sentMessages)
    setFailedMessages(failedMessages)
    setActiveUsers(activeUsers)
    setInActiveUsers(inActiveUsers)

  }
  fetchData()
  setLoading(false); // Stop loading
},[])

return { packages, user_packages, sentMessages, failedMessages, activeUsers,inActiveUsers };
}



export function useFetchBuyPackage() {

  const authHeader = useAuthHeader()

  const [packages, setPackages] = useState([])

  useEffect(()=>{
    async function fetchData() {
      const packages = await getRequest("packages/", authHeader())
      setPackages(packages)
    }
    fetchData()
  },[])

  return { packages };
}


export function useFetchSenderID() {

  const auth = useAuthUser()()
  const authHeader = useAuthHeader()

  const [sender_ID, setSenderID] = useState("")

  useEffect(()=>{
    async function fetchData() {
      const user = await getRequest(`users/${auth?.userID}`, authHeader())
      user.map((user) => {
        setSenderID(user.sender_ID)
      })
      
    }
    fetchData()
  },[])

  return { sender_ID };
}

export function useFetchSenderIDRequests() {

  const authHeader = useAuthHeader()

  const [requests,setRequests] = useState([])

  useEffect(()=>{
    async function fetchData() {
      const requests = await getRequest("users/requests/", authHeader())
      setRequests(requests)
    }
    fetchData()
  },[requests])

  return { requests };
}

export function useFetchSendSMS() {

  const authHeader = useAuthHeader()

  const [shortMessage, setShortMessage] = useState('')
  const [group, setGroupID] = useState('')
  const [contacts,setContacts] = useState([])
  const [contact_group,setContactsGroup] = useState([])
  const [templates,setTemplates] = useState([])
  const [groups,setGroups] = useState([])


  useEffect(()=>{
    async function fetchData() {
      const contacts = await getRequest("contacts/", authHeader())
      const contact_group = await getRequest("groups/contactGroup/", authHeader())
      const templates = await getRequest("templates/", authHeader())
      const groups = await getRequest("groups/", authHeader())
      let templateBody = JSON.parse(localStorage.getItem('templateBody'))
      let groupId = JSON.parse(localStorage.getItem('groupId'))
      
      setContacts(contacts)
      setContactsGroup(contact_group)
      setTemplates(templates)
      setGroups(groups)
      setShortMessage(templateBody)
      setGroupID(groupId)

      // const itemsToRemove = ['templateBody', 'groupId']
      const itemsToRemove = ['templateBody']
      itemsToRemove.forEach(item => localStorage.removeItem(item))
      
    }
    fetchData()

  },[])

  return { shortMessage, group, contacts, contact_group, templates, groups };
}

export function useFetchSubscribe() {

  const authHeader = useAuthHeader()

    const {id} = useParams(); // getting url id 

    const [packages, setPackages] = useState([])
    const [package_id, setPackageId] = useState("")

    useEffect(()=>{
      async function fetchData() {
        const packages = await getRequest(`packages/`, authHeader())
        setPackages(packages)
        const packag = await getRequest(`packages/${id}`, authHeader())
        packag.map((packag) => {
          setPackageId(packag.id)
        })
      }
      fetchData()
    },[])

  return { packages, package_id };
}

export function useFetchBilling() {

  const authHeader = useAuthHeader()

  const [packages,setPackages] = useState([])
  const [user_packages,setUserPackages] = useState([])

  useEffect(()=>{
    async function fetchData() {
      const packages = await getRequest("packages/", authHeader())
      const user_packages = await getRequest("user-package/", authHeader())
      setPackages(packages)
      setUserPackages(user_packages)
    }
    fetchData()
  },[])

return { packages, user_packages };
}

export function useFetchChat() {

  const authHeader = useAuthHeader()

  const [chartData, setChartData] = useState({});

  useEffect(() => {
    async function getData(){
      // fetch data from an API or any other source
      const data = await getRequest("packages/", authHeader())
      
      // transform the data to the format expected by the chart
      const chartData = {
        labels: data.map(item => item.label),
        datasets: [
          {
            label: "Data",
            data: data.map(item => item.value),
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: true,
          },
        ],
      };

      setChartData(chartData);
    };

    getData();
  }, []);

return { chartData };
}


