import Header from '../../includes/dashboards/Header';
import UserMenu from '../nav/UserMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getRequest, patchRequest } from '../../../requests/requests';
import { useFetchAuth, useCheckClient } from "../../../hooks/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthHeader } from 'react-auth-kit'

const ChangePass = () => {
  const authHeader = useAuthHeader()

  const {id} = useParams(); // getting url id

  useFetchAuth();
  useCheckClient();
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [mobile_number, setMobileNumber] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    async function fetchData() {
      const user = await getRequest(`users/${id}`, authHeader());
      user.map((user) => {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setMobileNumber(user.mobile_number);
        setEmail(user.email);
      })

    }
    fetchData()
  })


  const onSubmit = async(e) => {
    e.preventDefault()

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(password)) {
      toast.error('Please enter a valid password with at least one uppercase, one lowercase, one special character, one digit and 6 characters long.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    if (password !== confirmPassword) {
      toast.error('Passwords never match!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      return
    }

    const data = { password }

    const updateUserPassword = await patchRequest(`auth/password/${id}`, data, authHeader())

    if(updateUserPassword.status === 200){
      setPassword('')
      setConfirmPassword('')

      toast.success(updateUserPassword.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
    }else{

      toast.error(updateUserPassword.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      setLoading(false);
      
    }
    
  }


  return (
    <div>  
      <ToastContainer />
        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <UserMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Update User Password</h4>
                              <p className="card-description">Please enter a valid password with at least one uppercase, one lowercase, one special character, one digit and 6 characters long.</p>
                              <form className="forms-sample" onSubmit={onSubmit}>
                                <div className="form-group">
                                  <label htmlFor="inputFistname1">Fistname</label>
                                  <input type="text" className="form-control" id="inputFistname1" value={first_name} onChange={(e) => setFirstName(e.target.value)} disabled/>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputLastname1">Lastname</label>
                                  <input type="text" className="form-control" id="inputLastname1" value={last_name} onChange={(e) => setLastName(e.target.value)} disabled/>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputMobileNumber1">Mobile Number</label>
                                  <input type="text" className="form-control" id="inputMobileNumber1" value={mobile_number} onChange={(e) => setMobileNumber(e.target.value)} disabled/>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputEmail1">Email</label>
                                  <input type="email" className="form-control" id="inputEmail1" value={email} onChange={(e) => setEmail(e.target.value)} disabled />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputPassword1">Password *</label>
                                  <input type="password" className="form-control" id="inputPassword1" value={password} onChange={(e) => setPassword(e.target.value)} />
                                  {password.length > 0 && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(password) && <p className="alert alert-warning">Please enter a valid password with at least one uppercase, one lowercase, one special character, one digit and 6 characters long.</p>}
                                </div>

                                <div className="form-group">
                                  <label htmlFor="inputConfirmPassword1">Confirm Password *</label>
                                  <input type="password" className="form-control" id="inputConfirmPassword1" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                  {password !== confirmPassword && <p className="alert alert-warning">Passwords never match.</p>}
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span className="ml-3">Update User Password</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default ChangePass
