import React, { useState, useRef, useEffect } from "react";
import Styles from './includes/dashboards/Styles';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from './../images/logo-black.png';
import AuthService from "../services/auth.service";
import { useSignIn, useAuthUser, useIsAuthenticated } from 'react-auth-kit'

function scrollToTop() {
  window.scrollTo(0, 0);
}


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

function Signin() {

  let navigate = useNavigate();
  const mySignIn = useSignIn()
  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser()()

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {

    if(isAuthenticated()){

          if(auth?.admin){
            navigate("/admin/dashboard");
          }else{
            navigate("/dashboard");
          }
          
    }else{
      
        const resMessage = "Please login!";
        setLoading(false);
        setMessage(resMessage);

    }
    
  }, []);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    //form.current.validateAll();
    if (1) {
      AuthService.login(email, password).then(
        (response) => {

          mySignIn({
            token: response.accessToken,
            expiresIn: 6000,
            tokenType: "Bearer",
            authState: {userID:response.user.id, email:response.user.email, name:response.user.first_name, admin:response.admin.isAdmin}
          });

          if(response.admin.isAdmin){
            navigate("/admin/dashboard");
          }else{
            navigate("/dashboard");
          }
          
        },
        (error) => {
          const resMessage = (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };


  return (
    <>
      <Styles />

      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" style={{ width: '50%' }} />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="fw-light">Sign in to continue.</h6>
                  <form className="pt-3" onSubmit={handleLogin}>

                    {message && (
                      <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                          {message}
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <input type="email" className="form-control form-control-lg" id="inputEmail1" placeholder="Email"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required]} />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control form-control-lg" id="inputPassword1"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        validations={[required]} />
                    </div>
                    {/* <div className="mt-3">
              <input className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type='submit' value='SIGN IN' />
            </div> */}

                    <div className="form-group">
                      <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span className="ml-3">Login</span>
                      </button>
                    </div>


                    {/* <CheckButton style={{ display: "none" }} ref={checkBtn} /> */}

                    {/* <div className="my-2 d-flex justify-content-between align-items-center">
              <div className="form-check">
                <label className="form-check-label text-muted">
                  <input type="checkbox" className="form-check-input" />
                  Keep me signed in
                </label>
              </div>
              <Link to="/forgot" className="auth-link text-black">Forgot password?</Link>
            </div> */}
                    {/* <div className="mb-2">
              <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                <i className="ti-facebook me-2"></i>Connect using facebook
              </button>
            </div> */}
                    <div className="text-center mt-4 fw-light">
                      Don't have an account? <Link to="/sign-up" className="text-primary">Create</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content-wrapper ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
      {/* <!-- container-scroller --> */}
    </>
  );
}

export default Signin
