import Header from './includes/Header';
import Menu from './nav/Menu';
import Footer from './includes/Footer';
import { Link } from 'react-router-dom';
import about from '../assets/img/about.png'
function scrollToTop() {
    window.scrollTo(0, 0);
  }
  

const About = ({ login_className }) => {
    return (
      <>  
        <Header />
        <Menu login_className = { login_className } />

        {/* Start Page Title Area */}
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>About Us</h2>
                                <ul>
                                    <li><Link to="/home">Home</Link></li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Title Area  */}
            
            {/* Start About Section */}
            <section className="about-area section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="about-content">
                                <div className="about-content-text">
                                    <h2><span className="color-text">Effortlessly</span> send mass text messages to your customers and clients</h2>
                                    <p>Welcome to <strong>Premium<span className="color-text" style={{ fontSize: '15px' }}>SMS</span></strong>, a leading provider of bulk SMS messaging solutions. We are a stand-alone portal powered
                                        by <a to={'https://innetechosting.com/'} target={'_blank'}>Innetec hosting</a>, ensuring the highest level of reliability and uptime for our clients. Our platform is designed 
                                        to be user-friendly and easy to navigate, allowing businesses and organizations of all sizes to send mass text messages 
                                        to their customers and clients quickly and efficiently.</p>

                                    <p>With a wide range of features and options, our platform is the perfect solution for anyone looking to send bulk SMS messages. 
                                        Whether you're a small business looking to send out promotions and discounts, or a large corporation looking to send out important 
                                        updates and alerts, our platform has everything you need.</p>

                                    <p>At <strong>Premium<span className="color-text" style={{ fontSize: '15px' }}>SMS</span></strong>, we are dedicated to providing our clients with the best possible service. 
                                        Our team of experts is always on hand to answer any questions and provide support when needed. So why wait? <Link to={'/sign-up'} target={'_blank'} onClick={scrollToTop}>Sign up</Link> for an account today and 
                                        start sending bulk SMS messages with ease!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="about-image">
                                <img src={ about } alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End About Section */}
        
        <Footer />
    </>
  )
}

export default About
