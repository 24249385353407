import Header from '../../includes/dashboards/Header';
import TemplateMenu from './../nav/TemplateMenu';
import Footer from '../../includes/dashboards/Footer';
import Theme from '../../includes/dashboards/Theme';
import { Link } from 'react-router-dom';
import { getRequest, deleteRequest } from '../../../requests/requests';
import Table from "./../../../components/Table";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useFetchAuth } from "../../../hooks/auth";
import {useAuthHeader} from 'react-auth-kit'

const Templates = () => {
  const authHeader = useAuthHeader()
  let navigate = useNavigate();

  useFetchAuth();

  const [templates,setTemplates] = useState([])

  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }


  useEffect(()=>{
    async function fetchData() {
      const templates = await getRequest("templates/", authHeader())
      setTemplates(templates)
    }
    fetchData()
  },[templates])

  const onDeleteTemplate = async (id) => {

    return await deleteRequest(`templates/${id}`, authHeader())

  }

  const selectTemplate = (body) =>{
 
    localStorage.setItem("templateBody", JSON.stringify(body));

    navigate("/send-sms");
    window.location.reload();

  }

  const templatesData = (templates, selectTemplate, onDeleteTemplate) => {
    return templates.map((template, i) => ({
      id: i + 1,
      category: template.category,
      message: template.body,
      actions: template.id !== 0 ? (
        <>
          <Link to={`/edit-template/${template.id}`} className="btn btn-outline-primary border border-primary m-1">
            <i className="fa fa-eye" aria-hidden="true"></i> View
          </Link>
          <button onClick={() => selectTemplate(template.body)} className="btn btn-outline-success border border-success m-1">
            <i className="fa fa-paper-plane" aria-hidden="true"></i> Use Template
          </button>
          <button onClick={() => onDeleteTemplate(template.id)} className="btn btn-outline-danger border border-danger m-1">
            <i className="fa fa-trash" aria-hidden="true"></i> Delete
          </button>
        </>
      ) : (
        <></>
      ),
    }));
  };
  

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <TemplateMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                    <Table
                        data={templatesData(templates, selectTemplate, onDeleteTemplate)}
                        columns={["ID", "Category", "Message", "Actions"]}
                        title="Template Messages"
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Templates;
