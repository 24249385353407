import { Link } from 'react-router-dom';

const Footer = () => {
    return ( 
        <>
            {/* <!-- content-wrapper ends --> */}
                    {/* <!-- partial:partials/_footer.html --> */}
                    <footer className="footer">
                    <div className="d-sm-flex justify-content-center justify-content-sm-between">
                        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"><Link to="/dashboard" target="_blank">Premium SMS</Link> powered by Innetec.</span>
                        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Copyright © {(new Date().getFullYear())}. All rights reserved.</span>
                    </div>
                    </footer>
        </>
     );
}
 
export default Footer;