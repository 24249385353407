import Header from '../includes/dashboards/Header';
import PackageMenu from './nav/PackageMenu';
import Footer from '../includes/dashboards/Footer';
import Theme from '../includes/dashboards/Theme';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from '../../requests/requests';
import { useFetchAuth } from "../../hooks/auth";
import {useAuthHeader} from 'react-auth-kit'

const Subscribe = () => {
  const authHeader = useAuthHeader()
  const {id} = useParams(); // getting url id 

  useFetchAuth();

  const [packages, setPackages] = useState([])
  const [package_id, setPackageId] = useState("")
  const [payment_method, setPaymentMethod] = useState("")
  const [message, setMessage] = useState("")
  const [successful, setSuccessful] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    async function fetchData() {
      const packages = await getRequest(`packages/`, authHeader())
      setPackages(packages)
      const packag = await getRequest(`packages/${id}`, authHeader())
      packag.map((packag) => {
        setPackageId(packag.id)
      })
    }
    fetchData()
  })

  const onSubmit = async(e) => {
    e.preventDefault() 

    if (!package_id) {
      alert('Please select package name')
      return
    }

    if (!payment_method || payment_method === "") {
      alert('Please select payment method')
      return
    }

    // const pack = { package_id, payment_method }

    // const subscribe = await postRequest(`user-package/`, pack)
  
    // if(subscribe.status === 200){

    //   setMessage(subscribe.message);
    //   setSuccessful(true);
    //   setLoading(false);

    // }else{

    //   setMessage(subscribe);
    //   setSuccessful(false);
    //   setLoading(false);
      
    // }
  }

  return (
    <div>  

        <div className="with-welcome-text">
          <div className="container-scroller">
            <Header />
            {/* <!-- partial --> */}
            <div className="container-fluid page-body-wrapper">

                <Theme />

                <PackageMenu />

                {/* <!-- partial --> */}

                  <div className="main-panel">
                      <div className="content-wrapper">
                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="container pt-5">
                                  <h3 className="mb-3 mt-5">Advance your bussiness communication today</h3>
                                  <p className="w-75 mx-auto mb-5">Choose a plan that suits you the best.</p>
                                  <form className="forms-sample" onSubmit={onSubmit}>
                                    {message && (
                                      <div className="form-group">
                                        <div
                                          className={
                                            successful ? "alert alert-success" : "alert alert-danger"
                                          }
                                          role="alert"
                                        >
                                          {message}
                                        </div>
                                      </div>
                                    )}

                                    <div className="form-group">
                                      <label htmlFor="inputPackage">Package</label>
                                      <select type="text" className="form-control" id="inputPackage" onChange={(e) => setPackageId(e.target.value)} >
                                      {packages.map((packag, index) => (
                                        <option key={index} value={packag.id} selected={packag.id === package_id}>
                                          {packag.name}
                                        </option>
                                      ))}
                                      </select>
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="inputPaymentMethod">Payment method</label>
                                      <select type="text" className="form-control" id="inputPaymentMethod" onChange={(e) => setPaymentMethod(e.target.value)} >
                                        <option value="">Select Payment Method</option>
                                        <option value="Bank Transfer">Bank Transfer</option>
                                      </select>
                                    </div>

                                    <div className="form-group">
                                      <button type="submit" className="btn btn-primary" disabled={loading}>
                                        {loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span className="ml-3">Subscribe</span>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                    <Footer />

                  </div>

                {/* <!-- main-panel ends --> */}

              </div>

              {/* <!-- page-body-wrapper ends --> */}

            </div>

            {/* <!-- container-scroller --> */}

          </div>
    </div>
  )
}

export default Subscribe
